import React from "react";

// Customizable Area Start
import {
    Box,
    Button,
    Grid,
    Typography,
} from "@material-ui/core";
import { background, logo } from "../../email-account-login/src/assets";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import "./WelcomeScreen.css";
// Customizable Area End

import WelcomeScreenController, {
    Props,
} from "./WelcomeScreenController.web";

export default class WelcomeScreen extends WelcomeScreenController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start
    // Customizable Area End
    render() {
        return (
            // Customizable Area Start
            <Grid container>
                <Grid item lg={6} md={6} sm={12} xs={12}>
                    <Box style={Styles.mainBoxW}>
                        <Box style={Styles.LoginLogoW}>
                            <Box style={Styles.logoBoxW}>
                                <Typography style={{ fontSize: '24px', textAlign: 'center', fontFamily:"Koho", fontWeight:"500" }}>Welcome To</Typography>
                            </Box>
                            <img style={Styles.logoW} src={logo} />
                            <Box style={Styles.buttonBoxW}>
                                {/* <Button onClick={() => this.props.navigation.navigate('ParentVerify')} style={Styles.button1}>Parent</Button> */}
                                <Carousel
                                    data-test-id = "CarouselREF_test"
                                    ref={this.carouselRef}
                                    showStatus={false}
                                    showArrows={false}
                                    interval={2000}
                                    selectedItem={this.state.selectedItem}
                                    onChange={this.handleSlideChange}>
                                    <div>
                                        <Typography style={{ textAlign: 'center', fontSize: '15px', color:"#535656", fontFamily:"Inter" }}>Recess Child Care provides flexible childcare solutions.
                                            Our hourly-based model allows you to schedule childcare services on your terms.
                                            Simply select the date, time, and location, and book your appointment.
                                        </Typography>
                                    </div>
                                    <div>
                                        <Typography style={{fontFamily:'KoHo, sans-serif', fontSize: "24px", marginBottom:"22px", fontWeight:"600"}}> Easily Book and Manage Your Child’s Daycare  On-The-Go</Typography>
                                        <Typography style={{ textAlign: 'center', fontSize: '15px', color:"#535656",fontFamily:"Inter" }}>Take the hassle out of finding 
                                            everyday childcare solutions with instant booking, seamless drop-offs, and flexible 
                                            scheduling options at one of our multiple daycare centers.
                                        </Typography>
                                    </div>
                                    <div>
                                        <Typography style={{fontFamily:'KoHo, sans-serif', fontSize: "24px" , marginBottom:"22px", fontWeight:"600"}}> Gain Access to Our Large Network of Babysitters</Typography>
                                        <Typography style={{ textAlign: 'center', fontSize: '15px', color:"#535656", fontFamily:"Inter" }}>Unlock a vast network of fully-vetted, 
                                            local reliable babysitters right at your fingertips with our app’s 
                                            dedicated babysitter portal. We take the guesswork out of finding and scheduling 
                                            your next babysitter.
                                        </Typography>
                                    </div>
                                </Carousel>
                                <Button data-test-id = "handleNext_button" onClick={this.handleNext} style={Styles.button2W}>{this.state.Text}</Button>
                            </Box>
                        </Box>
                    </Box>
                </Grid>
                <Grid style={{
                        ...Styles.mainGridW,
                        backgroundImage: `url(${this.carouselImages[this.state.selectedItem]})`,
                    }} item lg={6} md={6}></Grid>
            </Grid>
            // Customizable Area End
        );
    }

}

// Customizable Area Start
// .carousel:hover .control-arrow {
//     display: none !important;
// }
const Styles = {
    mainBoxW: {
        // backgroundImage: `url(${backgroundImage})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        height: '100vh',
        display: 'flex' as 'flex',
        flexWrap: 'wrap' as 'wrap',
        justifyContent: 'center',
        alignItems: 'center',

    },
    mainGridW: {
        // backgroundImage: `url(${imgGrid})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: "center",
        height: '100vh',
    },
    LoginLogoW: {
        width: '390px',
        borderRadius: '10px',
        overflow: 'hidden',
        padding: '0px 55px 33px 55px',
    },
    logoBoxW: {
        padding: '0px 30px'
    },
    logoW: {
        width: '65%',
        margin: '15px auto 22px',
        display: 'flex'
    },
    welcomeLogoW: {
        margin: '30px auto',
        display: 'flex'
    },
    buttonBoxW: {
        display: 'grid',
        gap: '25px',
    },
    button1W: {
        width: '100%',
        height: '60px',
        backgroundColor: 'F1544B',
        color: '#fff',
        fontSize: '16px',
        fontWeight: 600
    },
    button2W: {
        width: '80%',
        height: '60px',
        backgroundColor: 'F1544B',
        color: '#fff',
        fontSize: '16px',
        fontWeight: 600,
        borderRadius: "20px",
        margin: 'auto',
        
    }



}
// Customizable Area End