import React from "react";
import {
  Drawer,
  Typography,
  Button,
  Box,
  IconButton,
  useTheme,
  Avatar,
} from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";

interface CongoKidProps {
  imageSrc:  any;
  open: boolean; 
  onClose: () => void; 
  handleAddChild: () => void;
}

const CongoKid: React.FC<CongoKidProps> = ({ imageSrc, open, onClose, handleAddChild }) => {
  const theme = useTheme(); // Access the theme for breakpoints
   console.log("imagesrc**" ,imageSrc) 
  const drawerWidth = 820; // Custom drawer width

  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={onClose}
      sx={{
        "& .MuiDrawer-paper": {
          width: drawerWidth,
          flexShrink: 0,
          borderRadius: "10px 0px 0px 10px",
          padding: "24px",
          display: "flex",
          zIndex:1,
          flexDirection: "column",
          alignItems: "center",
          textAlign: "center",
        },
        ".menuButton": {
          marginRight: theme.spacing(2),
          [theme.breakpoints.up("md")]: {
            display: "none",
          },
        },
        ".toolbar": {
          ...theme.mixins.toolbar,
          [theme.breakpoints.down("sm")]: {
            display: "none",
          },
        },
      }}>
     <Avatar
          src= {imageSrc  && typeof imageSrc == 'string'? imageSrc : imageSrc  ? URL.createObjectURL(imageSrc)  : ""}
          sx={{ width: 100, height: 100, mb: 1 , ml:5 , mr:5 }}
        /> 
      <Typography variant="h5" fontWeight={700} gutterBottom>
        Congrats!
      </Typography>
      <Typography variant="body1" color="textSecondary" gutterBottom>
        We can't wait to meet you!
      </Typography>
      <IconButton
        onClick={handleAddChild}
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          marginTop: "16px",
          color:"#f15642"
        }}
      >
        <AddCircleOutlineIcon fontSize="large" />
        <Typography variant="body2" color="error">
          Add another child
        </Typography>
      </IconButton>
      <Button
        variant="contained"
        onClick={onClose}
        fullWidth
        sx={{
          borderRadius: "8px",
          height: "48px",
          fontWeight: 700,
          marginTop: "24px",
          bgcolor: "#f15642"
        }}
      >
        Go Home
      </Button>
    </Drawer>
  );
};

export default CongoKid;
