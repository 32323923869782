import  React from "react"
import type { FormikProps } from "formik"
import { Box, Typography, Grid, TextField, Button, CardContent, Card, styled, Avatar, IconButton, Paper, Divider } from "@mui/material"
import { format } from "date-fns"
import { Allergen, PottyTraining, SolidAndPuree, NapOption, FormValues } from "./AddKId"
import FilePreview from "./FilePreview"

interface KidPreViewprops {
  formikProps: FormikProps<FormValues>
  
}
const InfoLabel = styled(Typography)({
    color: "#666",
    fontSize: "0.875rem",
    marginBottom: "4px",
  })
  
  const InfoValue = styled(Typography)({
    fontSize: "0.875rem",
    marginBottom: "16px",
  })
  
  const Section = styled(Box)(({ theme }) => ({
    marginBottom: theme.spacing(3),
  }))
  
export default function KidPreView({
  formikProps,
  
}: KidPreViewprops) {
  const { values, setFieldValue, errors, touched } = formikProps


  const StyledCard = styled(Card)(({ theme }) => ({
    
    margin: "auto",
    textAlign: "center",
    padding: theme.spacing(2),
  }))
  
  const ImageWrapper = styled(Box)(({ theme }) => ({
    width: 120,
    height: 120,
    margin: "0 auto",
    marginBottom: theme.spacing(2),
    borderRadius: "5%",
    overflow: "hidden",
    
  }))

  return (
    <Paper sx={{border:0 }} elevation={0}>
      {/* Profile Section */}
      <Box display="flex" alignItems="center" mb={4}>
      <Avatar
          src={values.profileImage ? URL.createObjectURL(values.profileImage) : ""}
          sx={{ width: 100, height: 100, mb: 1 , ml:5 , mr:5 }}
        />
       
        <Box>
          <Typography variant="subtitle1" fontWeight="bold">
          {values.firstName} {values.lastName}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {values.birthday}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            ID: {values.profile_id}
          </Typography>
        </Box>
      </Box>

      <Divider sx={{ mb: 3 }} />

      {/* Personal Information */}
      <Section>
        <Typography variant="subtitle1" fontWeight="bold" mb={4}>
          Personal Information
        </Typography>
        <Grid container spacing={3}>
          <Grid item xs={2}>
            <InfoLabel>First Name</InfoLabel>
            <InfoValue> {values.firstName} </InfoValue>
          </Grid>
          <Grid item xs={4}>
            <InfoLabel>Last Name</InfoLabel>
            <InfoValue>{values.lastName}</InfoValue>
          </Grid>
          <Grid item xs={4}>
            <InfoLabel>Date of Birth</InfoLabel>
            <InfoValue>{values.birthday}</InfoValue>
          </Grid>
          <Grid item xs={2}>
            <InfoLabel>Gender</InfoLabel>
            <InfoValue>{values.gender}</InfoValue>
          </Grid>
        </Grid>
      </Section>

      {/* Address Information */}
      <Typography variant="subtitle1" fontWeight="bold" mb={4}>
      Address Information
        </Typography>
      <Section>
        <Grid container spacing={3}>
          <Grid item xs={2}>
            <InfoLabel>Address</InfoLabel>
            <InfoValue>{values.addressLine1}</InfoValue>
            <InfoValue>{values.addressLine2}</InfoValue>
          </Grid>
          <Grid item xs={4}>
            <InfoLabel>Apt</InfoLabel>
            <InfoValue>{values.apt}</InfoValue>
          </Grid>
          <Grid item xs={2}>
            <InfoLabel>City</InfoLabel>
            <InfoValue>{values.city}</InfoValue>
          </Grid>
          <Grid item xs={2}>
            <InfoLabel>State</InfoLabel>
            <InfoValue>{values.state}</InfoValue>
          </Grid>
          <Grid item xs={2}>
            <InfoLabel>Zipcode</InfoLabel>
            <InfoValue>{values.zipcode}</InfoValue>
          </Grid>
        </Grid>
      </Section>

      {/* Additional Information */}
      <Typography variant="subtitle1" fontWeight="bold" mb={4}>
      Additional Information
        </Typography>
      <Section>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <InfoLabel>Legal Custody</InfoLabel>
            <InfoValue>{values.legalCustody}</InfoValue>
          </Grid>
          <Grid item xs={6}>
            <InfoLabel>Language</InfoLabel>
            <InfoValue>{values.language}</InfoValue>
          </Grid>
        </Grid>
      </Section>

    

      {/* Special Notes */}
      <Section>
        <InfoLabel>Special Notes</InfoLabel>
        <InfoValue>{values.special_notes}</InfoValue>
      </Section>
    </Paper>
  )
}

