import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import React from "react";
export const configJSON = require("./config");
import { imgGrid1, imgGrid2, imgGrid3 } from "./assets";
import { getStorageData } from "../../../framework/src/Utilities";
// Customizable Area End


export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    selectedItem: number;
    Text: string;
    getParantData: object;
    // Customizable Area End
}

interface SS {
    // Customizable Area Start
    id: any;
    // Customizable Area End
}

export default class HomePageController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    labelTitle: string = "";
    carouselRef: React.RefObject<any> = React.createRef();
    carouselImages: string[];
    getParentsDataCallId: string = "";
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.CountryCodeMessage),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.ReciveUserCredentials),
        ];

        this.state = {
            selectedItem: 0,
            Text: "Next",
            getParantData: {},
        };

        this.carouselImages = [imgGrid1, imgGrid2, imgGrid3];
        // Customizable Area End

        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    async componentDidMount() {
        // Customizable Area Start
         this.apicall()
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    async receive(from: string, message: Message) {
        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            var responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            if (apiRequestCallId === this.getParentsDataCallId) {
                try {
                    if (apiRequestCallId !== this.getParentsDataCallId) return;

                    const userAttributes = responseJson?.data?.[0]?.attributes;
                
                    if (!userAttributes) return;
                
                    const isFirstNameNull = userAttributes.first_name === null;
                    const isPrimaryNonParent = userAttributes.account_type?.includes("primary") && userAttributes.designation !== "parent";
                
                    if ((isFirstNameNull && this.state.selectedItem === 2) || isPrimaryNonParent) {
                        this.props.navigation.navigate('CustomisableUserProfileSetup');
                    } else {
                        this.props.navigation.navigate('CustomisableUserProfiles');
                    }
                }  catch (error) {
                    console.error('Error handling API response:', error);
                }
            }
        }


        // Customizable Area End
    }
    // Customizable Area Start
    
    handleNext = () => {
        const { selectedItem } = this.state;
        if (selectedItem === 2) {
            this.apicall()
        } else if (this.carouselRef.current) {
            this.carouselRef.current.increment();
        }
    };

    handleSlideChange = (index: number) => {
        this.setState({ selectedItem: index });
        if (index === 2) {
            this.setState({ Text: "Next" })
        } else if (index === 0) {
            this.setState({ Text: "Next" })
        }
    };

    goToLoginScreen = async () => {
        this.props.navigation.navigate('EmailAccountLoginBlock')
    };

    apicall = () => {
        const token =  localStorage.getItem("authTokenParant")
        const header = {
            "Content-Type": configJSON.searchApiContentType,
            token: token
        };
        const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.getParentsDataCallId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `account_block/accounts/get_parents`
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
        return true;
    }
    // Customizable Area End
}