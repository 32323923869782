Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.httpGetMethod = "GET";
exports.httpPostMethod = "POST";
exports.httpPutMethod = "PUT";
exports.searchApiContentType = "application/json";
exports.getSearchApiEndPoint = "search/users";
exports.errorTitle = "Error";
exports.getCustomizableProfileEndPoint = "bx_block_profile/profile/current_user_profile";
exports.updateCustomizableProfileEndPoint = "bx_block_profile/profile/update_user_profile";
exports.updateReasonUnenrollingEndPont = "bx_block_subscription_billing/cancel_subscription",
exports.getCustomizableProfileFieldsEndPoint =
  "bx_block_profile/profile/custom_user_profile_fields";
exports.accountsAPiEndPoint = "/account_block/accounts/parent_setup_profile"
exports.apipatchMethod = "PATCH";
exports.apideleteMethod = "DELETE";
exports.createApiContantType = "application/x-www-form-urlencoded";
exports.bankCreateAPiEndPoint ="/bx_block_stripe_integration/bank_accounts"
exports.bankVerifyAPiEndPoint = "/bx_block_stripe_integration/verify_account"
exports.getTermsAndC = "/account_block/terms_and_conditions"
// Customizable Area End
