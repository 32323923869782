import React from 'react';
import { Box, TextField, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';

export default function Step4ACH({errorMessage, formikProps, bankingDetails , Conformationmassege}: {errorMessage?: string, formikProps: any,bankingDetails?:{} , Conformationmassege?:string}) {
  const { values, errors, touched, handleChange, isSubmitting, setFieldTouched, setFieldValue } = formikProps;

  const disableSubmitButton = () =>{
    if(bankingDetails){
       const disbale=Object.keys(bankingDetails).map(key=>{
         const val=bankingDetails as any
         if(val[key]===values[key]){
           return true
          }
          return false
        }).filter(item=>!item)
        
       if(disbale.length){
        return false
       }
       
       return true
      }
      return false
  }

  return (
    <Box>
      <Typography variant="h4" component="h2" sx={{ mb: 1, fontFamily:'KoHo, sans-serif', color:"#000", fontWeight:"500" }}>
        Add ACH Payment
      </Typography>
      <Typography variant="body1" color="text.secondary" sx={{ mb: 4,fontFamily:'KoHo, sans-serif', color:"#000", fontSize:"18px" }}>
        Please complete this form to set up electronic payments.This payment method will be used for all bookings made through the app as well as fees for enrollment.
      </Typography>

      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
        <TextField
          fullWidth
          name="accountHolderName"
          label="Account Holder Name"
          value={values.accountHolderName}
          onChange={handleChange}
          error={touched.accountHolderName && Boolean(errors.accountHolderName)}
          helperText={touched.accountHolderName && errors.accountHolderName}
          sx={{
            "& .MuiOutlinedInput-root": {
              borderRadius: "12px",
              fontFamily:"Inter, sans-serif",
              fontWeight:"regular",
              color:"#000"
              },
  
              '& .MuiInputLabel-root': { color: '#c4c4c4' }, 
              '& .MuiInputLabel-root.Mui-focused': { color: '#1976d2' },
          }}
          
        />

        <TextField
          fullWidth
          name="accountNumber"
          label="Account Number"
          type="text"
          value={values.accountNumber}
          onChange={handleChange}
          error={touched.accountNumber && Boolean(errors.accountNumber)}
          helperText={touched.accountNumber && errors.accountNumber}
          sx={{
            "& .MuiOutlinedInput-root": {
              borderRadius: "12px",
              fontFamily:"Inter, sans-serif",
              fontWeight:"regular",
              color:"#000"
            },

            '& .MuiInputLabel-root': { color: '#c4c4c4' }, 
            '& .MuiInputLabel-root.Mui-focused': { color: '#1976d2' },
          }}
        />

        <TextField
          fullWidth
          name="confirmAccountNumber"
          label="Confirm Account Number"
          type="text"
          value={values.confirmAccountNumber}
          onChange={handleChange}
          error={touched.confirmAccountNumber && Boolean(errors.confirmAccountNumber)}
          helperText={touched.confirmAccountNumber && errors.confirmAccountNumber}
          sx={{
            "& .MuiOutlinedInput-root": {
              borderRadius: "12px",
              fontFamily:"Inter, sans-serif",
              fontWeight:"regular",
              color:"#000"
            },

            '& .MuiInputLabel-root': { color: '#c4c4c4' }, 
            '& .MuiInputLabel-root.Mui-focused': { color: '#1976d2' },
          }}
        />

        <TextField
          fullWidth
          name="routingNumber"
          label="Routing Number"
          value={values.routingNumber}
          onChange={handleChange}
          error={touched.routingNumber && Boolean(errors.routingNumber)}
          helperText={touched.routingNumber && errors.routingNumber}
          sx={{
            "& .MuiOutlinedInput-root": {
              borderRadius: "12px",
              fontFamily:"Inter, sans-serif",
              fontWeight:"regular",
              color:"#000"
            },

            '& .MuiInputLabel-root': { color: '#c4c4c4' }, 
            '& .MuiInputLabel-root.Mui-focused': { color: '#1976d2' },
          }}
        />

        <TextField
          fullWidth
          name="confirmRoutingNumber"
          label="Confirm Routing Number"
          value={values.confirmRoutingNumber}
          onChange={handleChange}
          error={touched.confirmRoutingNumber && Boolean(errors.confirmRoutingNumber)}
          helperText={touched.confirmRoutingNumber && errors.confirmRoutingNumber}
          sx={{
            "& .MuiOutlinedInput-root": {
              borderRadius: "12px",
              fontFamily:"Inter, sans-serif",
              fontWeight:"regular",
              color:"#000"
            },

            '& .MuiInputLabel-root': { color: '#c4c4c4' }, 
            '& .MuiInputLabel-root.Mui-focused': { color: '#1976d2' },
          }}
        />

        <TextField
          fullWidth
          name="billingZipCode"
          label="Billing Zip Code"
          value={values.billingZipCode}
          onChange={(e)=>{; {
            const BillingZipCode = e.target.value.replace(/\D/g, "");
            if (BillingZipCode.length === 5) {
              setFieldTouched("billingZipCode", false);
            } else {
              setFieldTouched("billingZipCode", true);
            }
            // handleChange(e)
            setFieldValue("billingZipCode", BillingZipCode);
          }}}
          inputProps={{ maxLength: 5, inputMode: "numeric", pattern: "[0-9]*" }}
          error={touched.billingZipCode && Boolean(errors.billingZipCode)}
          helperText={touched.billingZipCode && errors.billingZipCode}
          sx={{
            "& .MuiOutlinedInput-root": {
              borderRadius: "12px",
              fontFamily:"Inter, sans-serif",
              fontWeight:"regular",
              color:"#000"
            },

            '& .MuiInputLabel-root': { color: '#c4c4c4' }, 
            '& .MuiInputLabel-root.Mui-focused': { color: '#1976d2' },
          }}
        />
        {
          Conformationmassege!=="" && <p style={{color:"#F1544B"}}> {Conformationmassege }</p>
         }

         {errorMessage !== "" && 
         <Typography style={{color:"#d32f2f"}}>{errorMessage}</Typography>}
        
        <LoadingButton
          // fullWidth
          variant="contained"
          type="submit"
          loading={isSubmitting}
          sx={{
            mt: 2,
            bgcolor: '#ff4d4f',
            borderRadius:"12px",
            padding:"20px 16px",
            fontFamily:"Inter, sans-serif",
            fontWeight:"600",
            textTransform:"capitalize",
            '&:hover': {
              bgcolor: '#ff7875',
            },
          }}
          disabled={disableSubmitButton()}
        >
          Next
        </LoadingButton>
      </Box>
    </Box>
  );
}

