import  React from "react"

import {
  Drawer,
  Box,
  Typography,
  Avatar,
  Button,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Grid,
  IconButton,
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
} from "@mui/material"
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns"
import { LocalizationProvider, DatePicker, TimePicker } from "@mui/x-date-pickers"
import CloseIcon from "@mui/icons-material/Close"
import EditIcon from "@mui/icons-material/Edit"
import { useState, useEffect } from "react"
import { format ,parseISO} from "date-fns"
import {  Inc_Report,  Inc_Child, Kid} from "../../blocks/dashboard/src/DashboardController.web"
import FilePreview from "./FilePreview"
const SUBTYPES_MAPPING: Record<string, readonly string[]> = {
  physical_injuries: [
    "Falls, slips, and trips",
    "Cuts, bruises, or scrapes",
    "Bumps or head injuries",
    "Sprains or strains",
    "Bites or scratches from other children",
  ],
  health_related_incidents: [
    "Allergic reactions (e.g., to food, insect stings)",
    "Asthma attacks or breathing difficulties",
    "Seizures or other medical conditions",
    "Symptoms of illness (fever, vomiting, diarrhea)",
  ],
  emotional_or_behavioral_issues: [
    "Tantrums or extreme emotional outbursts",
    "Bullying or aggressive behavior towards other children",
    "Withdrawal or signs of emotional distress",
    "Any unusual or concerning changes in behavior",
  ],
  safety_and_security_incidents: [
    "Unauthorized person attempting to pick up a child",
    "Lost or missing child (even if briefly)",
    "Breach of security (e.g., unlocked doors, strangers on premises)",
  ],
  environmental_incidents: [
    "Fire, flood, or other natural disasters",
    "Exposure to hazardous materials",
    "Power outages or utility issues",
  ],
  accidents_involving_equipment_or_toys: ["Injury due to broken or malfunctioning toys/equipment", "Choking hazards"],
  nutrition_and_food_related_incidents: ["Choking Incidents", "Issues with dietary restrictions or feeding problems"],
  incidents_involving_transportation: [
    "Accidents or issues during transport in daycare vehicles",
    "Issues during field trips or outings",
  ],
  legal_or_policy_violations: [
    "Violations of daycare policies by staff or parents",
    "Situations that may require mandatory reporting (e.g., suspected abuse or neglect)",
  ],
  other_unforeseen_incidents: ["Any other incidents that are out of the ordinary and require attention"],
}

export const LOCATION_IN_FACILITY = {
  flex_care_space: "Flex-Care Space",
  event_space: "Event Space",
  class_space: "Class Space",
  lobby: "Lobby",
  outside_space: "Outside Space",
} 

interface IncidentFormData {
  date: string
  time: string
  location_id: string
  child_profile_id: string
  location_in_facility: keyof typeof LOCATION_IN_FACILITY
  incident_type: keyof typeof SUBTYPES_MAPPING
  subtype: string
  witness: string
  description: string
  action_taken: string
  notified_person: string
  notified_date: string
  notification_mode: string
  escalation: string
  parent_response: string
  documents: File[]
}

interface IncidentFormDrawerProps {
  open: boolean
  setOpen: (open: boolean) => void
  childData: {id:string,name:string, image:string, age:string|number} |null
  editFlag?: boolean
  incidentData?: Inc_Report |null|Kid
  onSubmit: (formData: FormData) => void
}

export default function IncidentFormDrawer({
  open,
  setOpen,
  childData,
  editFlag = false,
  incidentData,
  onSubmit,
}: IncidentFormDrawerProps) {
  const [isEditing, setIsEditing] = useState(!editFlag)
  const [formData, setFormData] = useState<IncidentFormData>({
    date: "",
    time: "",
    location_id: "",
    child_profile_id: childData?.id.toString()??"",
    location_in_facility: "flex_care_space",
    incident_type: "physical_injuries",
    subtype: "",
    witness: "",
    description: "",
    action_taken: "",
    notified_person: "",
    notified_date: "",
    notification_mode: "phone",
    escalation: "No",
    parent_response: "",
    documents: [],
  })

  useEffect(() => {
    if (incidentData && editFlag) {
      const { attributes } = incidentData
      setFormData({
        date: 'date' in attributes ? attributes.date : "",
        time: 'time' in attributes ? attributes.time : "",
        location_id: 'location' in attributes ? attributes.location.id.toString() : "",
        child_profile_id: 'child' in attributes ? attributes.child.id.toString() : "",
        location_in_facility: 'location_in_facility' in attributes ? attributes.location_in_facility as keyof typeof LOCATION_IN_FACILITY : "flex_care_space",
        incident_type: 'incident_type' in attributes ? attributes.incident_type as keyof typeof SUBTYPES_MAPPING : "physical_injuries",
        subtype: 'subtype' in attributes ? attributes.subtype : "",
        witness: 'witness' in attributes ? attributes.witness : "",
        description: 'description' in attributes ? attributes.description:"",
        action_taken:'action_taken' in attributes ? attributes.action_taken:"",
        notified_person: 'notified_person' in attributes ?attributes.notified_person:"",
        notified_date:'notified_date' in attributes ? attributes.notified_date:"",
        notification_mode: 'notification_mode' in attributes && attributes.notification_mode ? attributes.notification_mode : "phone",
        escalation:'escalation' in attributes ? attributes.escalation:"",
        parent_response: 'parent_response' in attributes ? attributes.parent_response : "",
        documents: [], // Can't convert URLs back to Files
      })
    }
  }, [incidentData, editFlag])

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault()
    const submitData = new FormData()

    // Convert form data to FormData format
    Object.entries(formData).forEach(([key, value]) => {
      if (key === "documents") {
        value.forEach((file: File) => {
          submitData.append(`incident[documents][]`, file)
        })
      } else {
        submitData.append(`incident[${key}]`, value.toString())
      }
    })

    onSubmit(submitData)
    setIsEditing(false)
  }

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      setFormData((prev) => ({
        ...prev,
        documents: Array.from(e.target.files || []),
      }))
    }
  }
  const subtypes = SUBTYPES_MAPPING[formData.incident_type as string] ?? [];
console.log(formData.time,"=====>188");


  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={() => {
        setIsEditing(false)
        setOpen(false)}}
      PaperProps={{
        sx: { width: { xs: "100%", sm: 600 }, p: 3 ,borderRadius:4, },
      }}
    >
      <Box component="form" onSubmit={handleSubmit} sx={{pb:2}}>
        {/* Header */}
        <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", mb: 3 }}>
        <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", gap:2 }}>
          <Typography variant="h6" sx={{fontWeight:'bold'}}>{editFlag ? "Edit Incident Report" : "New Incident Report"}</Typography>
          {editFlag && (
            <Button
              startIcon={<EditIcon sx={{color:'#f1544b'}}/>}
              onClick={() => setIsEditing(true)}
              sx={{ visibility: isEditing ? "hidden" : "visible" ,color:"#f1544b"}}
            >
              Edit
            </Button>
          )}
          </Box>
          <IconButton onClick={() => {setIsEditing(false),setOpen(false)}}>
            <CloseIcon />
          </IconButton>
        </Box>

        {/* Child Info */}
       {childData && <Box sx={{ display: "flex", alignItems: "center", mb: 4 }}>
          <Avatar src={childData.image} sx={{ width: 60, height: 60, mr: 2 }} />
          <Box>
            <Typography variant="h6">{childData.name}</Typography>
            <Typography variant="body2" color="text.secondary">
              {childData.age} years old
            </Typography>
          </Box>
        </Box>}

        {isEditing ? (
          <Stack spacing={3}>
            {/* Form Fields */}
            <Grid container spacing={2}>
              {/* Date and Time */}
              <Grid item xs={12} md={6}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    label="Date of Incident"
                    value={formData.date ? new Date(formData.date) : null}
                    onChange={(newValue) => {
                      setFormData((prev) => ({
                        ...prev,
                        date: newValue ? format(newValue, "yyyy-MM-dd") : "",
                      }))
                    }}
                  />
                </LocalizationProvider>
              </Grid>

              <Grid item xs={12} md={6}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                
                  <TimePicker
                    label="Time of Incident"
                    value={formData.time ? new Date(formData.time) : null} // Ensures UTC parsing
                    onChange={(newValue) => {
                      if (newValue) {
                        // Convert selected time to UTC and format it as HH:mm
                        const utcTime = format(
                          new Date(newValue.getTime() - newValue.getTimezoneOffset() * 60000),
                          "HH:mm"
                        );

                        setFormData((prev) => ({
                          ...prev,
                          time: utcTime, // Stores in UTC
                          timeZone: "UTC"
                        }));
                      } else {
                        setFormData((prev) => ({
                          ...prev,
                          time: "",
                          timeZone: "UTC"
                        }));
                      }
                    }}
                  />

                </LocalizationProvider>
              </Grid>

              {/* Location */}
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputLabel>Location in Facility</InputLabel>
                  <Select
                    value={formData.location_in_facility}
                    onChange={(e) =>
                      setFormData((prev) => ({
                        ...prev,
                        location_in_facility: e.target.value as keyof typeof LOCATION_IN_FACILITY,
                      }))
                    }
                    label="Location in Facility"
                  >
                    {Object.entries(LOCATION_IN_FACILITY).map(([key, value]) => (
                      <MenuItem key={key} value={key}>
                        {value}
                      </MenuItem>
                    ))}
                  </Select>
                  
                </FormControl>
              </Grid>

              {/* Incident Type and Subtype */}
              <Grid item xs={12} md={6}>
                <FormControl fullWidth>
                  <InputLabel>Type of Incident</InputLabel>
                  <Select
                    value={formData.incident_type}
                    onChange={(e) =>
                      setFormData((prev) => ({
                        ...prev,
                        incident_type: e.target.value as keyof typeof SUBTYPES_MAPPING,
                        subtype: "",
                      }))
                    }
                    label="Type of Incident"
                  >
                    {Object.keys(SUBTYPES_MAPPING).map((key) => (
                      <MenuItem key={key} value={key}>
                        {key
                          .split("_")
                          .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                          .join(" ")}
                      </MenuItem>
                    ))}
                  </Select>
                
                </FormControl>
               
              </Grid>

              <Grid item xs={12} md={6}>
                <FormControl fullWidth disabled={!formData.incident_type}>
                  <InputLabel>Subtype</InputLabel>
                  <Select
                    value={formData.subtype}
                    onChange={(e) =>
                      setFormData((prev) => ({
                        ...prev,
                        subtype: e.target.value,
                      }))
                    }
                    label="Subtype"
                  >
                    {subtypes.map((subtype:string) => (
                      <MenuItem key={subtype} value={subtype}>
                        {subtype}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              
              </Grid>

              {/* Other Fields */}
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Witness"
                  value={formData.witness}
                  onChange={(e) => setFormData((prev) => ({ ...prev, witness: e.target.value }))}
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  fullWidth
                  multiline
                  rows={4}
                  label="Description"
                  value={formData.description}
                  onChange={(e) => setFormData((prev) => ({ ...prev, description: e.target.value }))}
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  fullWidth
                  multiline
                  rows={4}
                  label="Action Taken"
                  value={formData.action_taken}
                  onChange={(e) => setFormData((prev) => ({ ...prev, action_taken: e.target.value }))}
                />
              </Grid>

              {/* Notification Section */}
              <Grid item xs={12}>
                <Typography variant="h6" gutterBottom>
                  Parent/Caretaker Notification
                </Typography>
              </Grid>

              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  label="Who was notified"
                  value={formData.notified_person}
                  onChange={(e) => setFormData((prev) => ({ ...prev, notified_person: e.target.value }))}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    label="Notification Date"
                    value={formData.notified_date ? new Date(formData.notified_date) : null}
                    onChange={(newValue) => {
                      setFormData((prev) => ({
                        ...prev,
                        notified_date: newValue ? format(newValue, "yyyy-MM-dd") : "",
                      }))
                    }}
                  />
                </LocalizationProvider>
              </Grid>

              <Grid item xs={12}>
                <FormControl>
                  <Typography variant="body2" color="text.secondary" gutterBottom>
                    Does this require escalation?
                  </Typography>
                  <RadioGroup
                    row
                    value={formData.escalation}
                    onChange={(e) => setFormData((prev) => ({ ...prev, escalation: e.target.value }))}
                  >
                    <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                    <FormControlLabel value="No" control={<Radio />} label="No" />
                  </RadioGroup>
                </FormControl>
              </Grid>

              <Grid item xs={12}>
                <TextField
                  fullWidth
                  multiline
                  rows={3}
                  label="Parent Response"
                  value={formData.parent_response}
                  onChange={(e) => setFormData((prev) => ({ ...prev, parent_response: e.target.value }))}
                />
              </Grid>

              {/* File Upload */}
              <Grid item xs={12}>
             {formData.documents.map((doc,index)=>{
                return <FilePreview file={doc} removeFn={()=>{ setFormData((prev) => ({
                  ...prev,
                  documents: [],
                }))}} key={index}/>
             })}
                <Button variant="outlined" component="label" fullWidth>
                  Upload Documents
                  <input
                    type="file"
                    hidden
                    onChange={(e) => {
                      if (e.target.files) {
                        setFormData((prev) => ({
                          ...prev,
                          documents: Array.from(e.target.files || []),
                        }))
                      }
                    }}
                  />
                </Button>
                {formData.documents.length > 0 && (
                  <Typography variant="body2" sx={{ mt: 1 }}>
                    {formData.documents.length} file selected
                  </Typography>
                )}
              </Grid>
            </Grid>

            {/* Submit Buttons */}
            <Box sx={{ display: "flex", justifyContent: "flex-end",pl:2, gap: 4, mt: 4 }}>
              <Button variant="contained" type="submit"fullWidth sx={{bgcolor:'#f1544b',border:"#f1544b",":hover":{
                  bgcolor:'#f1544b'
                } }}>
                {editFlag ? "Update" : "Submit"}
              </Button>
              <Button variant="outlined" onClick={() => setOpen(false)} fullWidth  sx={{border:"1px solid gray", color:'gray',":hover":{
                bgcolor:'#ffff'
              }}}>
                Cancel
              </Button>
              
            </Box>
          </Stack>
        ) : (
          // Preview Mode
          <Stack spacing={3}>
            <Typography variant="h6"  sx={{fontWeight:'bold'}}>Incident Information</Typography>

            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Typography color="text.secondary">Date of Incident</Typography>
                <Typography>{formData.date}</Typography>
              </Grid>

              <Grid item xs={6}>
                <Typography color="text.secondary">Time of Incident</Typography>
                  <Typography>
                    {new Date(formData.time).toLocaleTimeString('en-US', {
                      timeZone: 'UTC',
                      hour: 'numeric',
                      minute: 'numeric',
                      hour12: true,
                    })}
                  </Typography>

              </Grid>

              <Grid item xs={6}>
                <Typography color="text.secondary">Location</Typography>
                <Typography>{formData.location_id}</Typography>
              </Grid>

              <Grid item xs={6}>
                <Typography color="text.secondary">Location in Facility</Typography>
                <Typography>{formData.location_in_facility}</Typography>
              </Grid>

              <Grid item xs={12}>
                <Typography color="text.secondary">Type of Incident</Typography>
                <Typography>
                  {formData.incident_type} - {formData.subtype}
                </Typography>
              </Grid>

              <Grid item xs={12}>
                <Typography color="text.secondary">Witness</Typography>
                <Typography>{formData.witness}</Typography>
              </Grid>

              <Grid item xs={12}>
                <Typography color="text.secondary">Description of Incident</Typography>
                <Typography>{formData.description}</Typography>
              </Grid>

              <Grid item xs={12}>
                <Typography color="text.secondary">Action Taken</Typography>
                <Typography>{formData.action_taken}</Typography>
              </Grid>

              <Grid item xs={12}>
                <Typography variant="h6" sx={{ mb: 2 , fontWeight:'bold'}}>
                  Parent/Caretaker Notification
                </Typography>

                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Typography color="text.secondary">Who was notified</Typography>
                    <Typography>{formData.notified_person}</Typography>
                  </Grid>

                  <Grid item xs={6}>
                    <Typography color="text.secondary">When were they notified</Typography>
                    <Typography>{formData.notified_date}</Typography>
                  </Grid>

                  <Grid item xs={12}>
                    <Typography color="text.secondary">Requires Escalation</Typography>
                    <Typography>{formData.escalation}</Typography>
                  </Grid>

                  <Grid item xs={12}>
                    <Typography color="text.secondary">Parent Response</Typography>
                    <Typography>{formData.parent_response}</Typography>
                  </Grid>

                  <Grid item xs={12}>
                    <Typography color="text.secondary">Documents</Typography>
                    <Typography>{formData.documents.length} file(s) attached</Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Stack>
        )}
      </Box>
    </Drawer>
  )
}
