
import React from "react";
// Customizable Area Start
import { Box, Typography, CircularProgress, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Avatar, IconButton } from "@mui/material";
import { ArrowForwardIos, Email } from "@mui/icons-material";

import { styled } from "@mui/material/styles";

import CommonHerderDateinput from "../../../components/src/CommonHerderDateinput";
import { SideBar } from "../../../components/src/SideBar";
import ChildProfileDrawer from "../../../components/src/ChildProfileDrawer";

import StringModal from "../../../components/src/StringModal";
import IncidentFormDrawer from "../../../components/src/IncidentFormDrawer";
// Customizable Area End
import DashboardController, {
  Props,
  webConfigJSON
} from "./DashboardController.web";

export default class DashboardChildrenScreen extends DashboardController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  async componentDidMount(): Promise<void> {
    this.getLocatations()
    this.sendGetAPIRequest(webConfigJSON.getChildresdata, 'apiChildreanCallId')
    this.sendGetAPIRequest(webConfigJSON.getTimeSloth,"apiCallTimeSlothgetCallId");
  }
  componentDidUpdate(prevProps: Props, prevState: any) {
    if (prevState.selectedLocation !== this.state.selectedLocation) {
      this.sendGetAPIRequest(webConfigJSON.getChildresdata, "apiChildreanCallId");
      this.sendGetAPIRequest(webConfigJSON.getTimeSloth,"apiCallTimeSlothgetCallId");
    }
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      //Merge Engine DefaultContainer
      <Box width={'100vw'} height={'100vh'} >
        <SideBar SideBarContent={<></>}
         updateQRCodeState={(data)=>this.updateQRCodeState(data)}
         tileSlothData={this.state.TimeSlothData}
          data-test-id = "sidebar"
          navigateFun={this.navigateFun}  selectedPage="MEMBERS"
          locations={this.state.locations || []}
          HeaderTitel={<Typography variant="h6" fontWeight="semibold" fontFamily={"Inter"} fontSize={'28px'}
          >
            {/* {this.formatDate(Date.now())}
             */}
                <CommonHerderDateinput selectedDate={this.state.selectedDate} setSelectedDate={(e)=>this.setState({selectedDate:e})}  />
          
          </Typography>}
          NavigationFunction={this.props.navigation.navigation}
          updateLocationState={this.updateLocationState}
        >
          <div style={{ padding: "20px" }}>
            <Typography variant="h6" gutterBottom style={{ fontWeight: "bold", marginBottom: "16px" }}>
              Members - Children
            </Typography>


            <TableContainer component={Paper}>
              <Table>
                <TableHead sx={{ marginBottom: '30px' }}>
                  <TableRow style={{ backgroundColor: "#f8d7da" }}>
                    <TableCell style={{ fontWeight: "bold", fontSize: 16 }}>
                      Name
                    </TableCell>
                    <TableCell style={{ fontWeight: "bold", fontSize: 16 }}>
                      Age
                    </TableCell>
                    <TableCell style={{ fontWeight: "bold", fontSize: 16 }}>
                      Caretaker
                    </TableCell>
                    <TableCell style={{ fontWeight: "bold", fontSize: 16 }}>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {this.state.ChildreanData?.data?.map((kid, index) => (
                    <TableRow
                      key={index}
                      style={{
                        backgroundColor:
                          index % 2 === 0 ? "#fde2e4" : "#ffffff",
                      }}
                    >
                      <TableCell>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <Avatar
                            src={kid.attributes.child_profile_image}
                            alt={kid.attributes.first_name}
                            style={{ marginRight: "10px" }}
                          />
                          {kid.attributes.first_name} {kid.attributes.last_name}
                        </div>
                      </TableCell>
                      <TableCell>{kid.attributes.age}yrs</TableCell>
                      <TableCell>{kid.attributes.care_taker.map(e => { return `${e.full_name},` })}</TableCell>
                      <TableCell>
                        <IconButton onClick={()=>this.openSelectedKid(kid)}>
                          <ArrowForwardIos fontSize="small"  />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>

          </div>
                  <ChildProfileDrawer
                    data={this.state.SelectedKidProfile}
                    open={this.state.OpenDrawerDashboard}
                    setOpen={() => this.toggleDrawerDashboardState()}
                    onIncidentReport={()=>{this.openIncidentDrawer(null)}}
                    data-test-id="child-profile-drawer"
                    />
                    <IncidentFormDrawer
                      open={this.state.OpenIncidentFormInKid}
                      setOpen={() => this.toggleDrawerIncidentInKidpage()}
                      childData={{id:'',name:this.state.SelectedKidProfile?.attributes.first_name ?? '', age:this.state.SelectedKidProfile?.attributes.age ?? 0 ,image:this.state.SelectedKidProfile?.attributes.child_profile_image ?? ''}}
                      onSubmit={(data) => this.createIncidentApiCall(data)}
                        data-test-id="child-profile-incForm"
                    />
                    <StringModal  message={this.state.QrMsg}/>
        </SideBar>
      </Box>
      //Merge Engine End DefaultContainer
    );
    // Customizable Area End
  }
}

// Customizable Area Start

const webStyles = {
  container: {
    display: "grid",
    gridTemplateColumns: "3fr 7fr",
    boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
    width: "32rem",
    padding: "0.5rem 1rem",
    minHeight: "12rem",
    gap: "2rem"
  },
  totalCandidates: {
    display: "grid",
    gridTemplateRows: "3fr 7fr",
    alignItems: "start",
    justifyContent: "center",
    justifyItems: "center",
    color: "black",
    margin: "1rem 0rem"
  },
  candidatesFontSize: {
    fontSize: "1rem"
  },
  btn: {
    backgroundColor: "blue",
    color: "white",
    borderRadius: "0",
    marginTop: "0.5rem",
    padding: "0.125rem"
  },
  fontSizeForType: { fontSize: "1 rem" },
  mediumFontSizeForType: { fontSize: "3rem" },
  itemStyle: { margin: "1rem 0rem" },
  type: { fontSize: "0.8rem", textTransform: "capitalize" },
  titletext: {
    color: "grey",
    fontWeight: "lighter",
    fontSize: "3rem"
  },
  typeText: { fontSize: "0.8rem", textTransform: "capitalize" },
  Colcont: { flex: 1, display: 'flex', flexDirection: 'column', background: '#ffff' },
  ColTitelGry: {
    fontSize: '18px',
    color: '3D475C',
    background: '#ffff'
  },


};
// Customizable Area End
