import React from "react";
// Customizable Area Start
import {
  createTheme,
  Theme,
} from "@mui/material/styles";
import {
    
    Box,
    Button,
    TextField,
    Typography,
    Paper,
   
  } from '@mui/material';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import AddIcon from '@material-ui/icons/Add';
import AddChild from "../../../components/src/AddChild";
import "@mui/styles";
const DatePicker = require("react-datepicker");

import "react-datepicker/dist/react-datepicker.css";

import { Form, Formik } from "formik";

import Step1Profile from "../../../components/src/Step1Profile";

import Step2Address from "../../../components/src/Step2Address";

import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

import Step3Photo from "../../../components/src/Step3Photo";

import Step4ACH from "../../../components/src/Step4ACH";

import Step5Verify from "../../../components/src/Step5Verify";

import Step6Terms from  "../../../components/src/Step6Terms";

import ProfileComplete from "../../../components/src/ProfileComplete";
// Customizable Area End

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});

import CustomisableUserProfilesController, {
  Props,
  IField,
} from "./CustomisableUserProfilesController";
import {  Container, Grid, styled, Divider, Drawer } from "@material-ui/core";
import {logo} from "./assets";
declare module "@mui/styles/defaultTheme" {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme { }
}

const drawerWidth = 850;

const CustomDrawer = styled(Drawer)(({ theme }) => ({
  "& .MuiDrawer-paper": {
    width: drawerWidth,
    // height: 'auto',
    flexShrink: 0,
    borderRadius: '10px 0px 0px 10px'
  },
  ".menuButton": {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  ".toolbar": {
    ...theme.mixins.toolbar,
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
}));



// Customizable Area End

export default class CustomisableUserProfileSetup extends CustomisableUserProfilesController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }


  render() {
    // Customizable Area Start

    
    return (
        <Container maxWidth="sm">
        <Paper 
          elevation={0} 
          sx={{ 
            p: 3,
            minHeight: 100,
          }}
        >
          <Typography
            variant="h3"
            component="h1"
            sx={{
              fontWeight: 'bold',
              textAlign: 'center',
              mb: 2,
              fontFamily: 'sans-serif',
              color: '#000',
            }}
          >
            <img src={logo} style={{height:'49px'}}/>
          </Typography>
          <div style={{width: "100%",
                height:"4px",
                backgroundColor:"#f2f2f2",
                margin:"32px 0"}}>
          <Box 
            sx={{ 
              width:`${Math.min(Math.max(10,this.state.activeStep*20),100)}%`, 
              height: '4px', 
              bgcolor: '#ff4d4f',
              mb: 4,
              position:"relative",
            }} 
          />
          </div>
          <Box sx={{ display: 'flex', alignItems: 'center', mb: 3, position:"relative"}}>
            {(this.state.activeStep > 0) && (
              <Button
                onClick={()=>this.handleBack()}
                sx={{ minWidth: 'auto', mr: 2, position:"absolute", left:"-40px" }}
              >
                <ArrowBackIosIcon style={{color:"#000"}} />
              </Button>
            )}
            <Typography variant="body2" color="text.secondary" style={{fontFamily:"KoHo", fontSize:"18px", color:"#535656"}}>
              Step {Math.min(this.state.activeStep + 1,6)} of {6}
            </Typography>
          </Box>
  
          <Formik
          initialValues={this.state.formValues}
          validate={this.validationFunctions[this.state.activeStep]}
          data-test-id = 'formik'
          onSubmit={(values, { setSubmitting }) => {
            this.updateValues(values)
            this.handleNext();
            setSubmitting(false);
          }}
          enableReinitialize={true} 
        >
           
          {(formikProps) => (
            <Form>
            {  this.state.activeStep ===0 && <Step1Profile formikProps={formikProps} />}
            {  this.state.activeStep ===1 && <Step2Address formikProps={formikProps} />}
            {  this.state.activeStep ===2 && <Step3Photo formikProps={formikProps} />}
            {  this.state.activeStep ===3 && <Step4ACH errorMessage={this.state.errorMessage} formikProps={formikProps} />}
            {  this.state.activeStep ===4 && <Step5Verify formikProps={formikProps}/>}
            {  this.state.activeStep ===5 && <Step6Terms data-test-id="step6" formikProps={formikProps} Terms={this.state.Terms} storeSignatureAPI={this.storeSignatureAPI}/>}
            {  this.state.activeStep ===6 && <ProfileComplete  navfn={this.goToChildProfile}/>}
           
            </Form>
          )}
        </Formik>
        </Paper>
       
      </Container>
  
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const Styles = {
  mainText: {
    fontSize: '40px',
    fontWeight: 600
  },
  childText: {
    fontSize: '16px',
    fontWeight: 400
  },
  child1Text: {
    fontSize: '16px',
    fontWeight: 400
  },
  Enrollment: {
    fontSize: '20px',
    fontWeight: 600
  },
  EnrollChild: {
    fontSize: '20px',
    display: 'flex'
  },
  box2_paper: {
    padding: '20px', 
    display: 'flex', 
    backgroundColor: 'F1F1F1'
  },
  profileImg:{
    width: '40px', 
    height: '40px', 
    margin: "0px 10px" 
  }
}
// Customizable Area End
