import React, { useState } from "react";
import { Box, Typography, Button, Modal } from "@mui/material";
import VisibilityIcon from '@mui/icons-material/Visibility';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';

const FilePreview = ({ file , removeFn }:{file:File , removeFn:()=>void}) => {
  const [open, setOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState<string | null>(null);

  const handleOpen = (imageUrl: string) => {
    setSelectedImage(imageUrl);
    setOpen(true);
  };

  const handleClose = () => {
    setSelectedImage(null);
    setOpen(false);
  };

  return (
    <Box>
        <Box  display="flex" alignItems="center" mb={1} mt={1} sx={{position:'relative'}}>
          {/* Thumbnail Image */}
          <Box
            component="img"
            src={URL.createObjectURL(file)}
            alt={file.name}
            sx={{
              width: 50,
              height: 50,
              borderRadius: 4,
              objectFit: "cover",
              marginRight: 2,
            }}
          />
          {/* File Name */}
          <Typography variant="body2" sx={{ flexGrow: 1 }}>
            {file.name}
          </Typography>

          <Button
            variant="outlined"
            type="button"
            size="small"
            color="error"
            onClick={removeFn} 
            sx={{mr:2}}
          >
            <DeleteIcon color="error"/>
          </Button>
           

          {/* View Button */}
          <Button
            variant="outlined"
            type="button"
            size="small"
            onClick={() => handleOpen(URL.createObjectURL(file))}
          >
            <VisibilityIcon/>
          </Button>

          
          
        </Box>
    

      {/* Modal for Enlarged Image */}
      <Modal open={open} onClose={handleClose}>
             
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 2,
            borderRadius: 2,
            textAlign: "center",
          }}
        >
        
          {selectedImage && (
            <Box component="img" src={selectedImage} alt="Selected" sx={{ maxWidth: "100%", maxHeight: "80vh" }} />
          )}
          
        </Box>
      </Modal>
    </Box>
  );
};

export default FilePreview;
