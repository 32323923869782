import React from "react"
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Box,
} from "@mui/material"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"

export default function AgeGroupsAccordion() {
  const ageGroups = [
    { name: "Infant (Under 1)", color: "#f05545" },
    { name: "Young Toddlers (One's)", color: "#ff9e40" },
    { name: "Old Toddlers (Two's)", color: "#ffeb3b" },
    { name: "Preschool (3-5 YOs)", color: "#4caf50" },
    { name: "Grade School (Over 5)", color: "#9c27b0" },
  ]

  return (
    <Box sx={{ maxWidth: 400, margin: "0 auto" }}>
      <Accordion defaultExpanded={false}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="age-groups-content" id="age-groups-header">
          <Typography variant="h6">Age Groups</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <List sx={{ width: "100%", bgcolor: "background.paper" }}>
            {ageGroups.map((group, index) => (
              <ListItem key={index}>
                <ListItemIcon>
                  <Box
                    sx={{
                      width: 24,
                      height: 24,
                      borderRadius: "50%",
                      bgcolor: group.color,
                    }}
                  />
                </ListItemIcon>
                <ListItemText primary={group.name} />
              </ListItem>
            ))}
          </List>
        </AccordionDetails>
      </Accordion>
    </Box>
  )
}

