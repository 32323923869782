import  React,{ ReactEventHandler, useState } from "react"
import { Formik, Form } from "formik"
import * as Yup from "yup"
import {
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  TextField,
  Grid,
  Avatar,
  Typography,
  Button,
  MenuItem,
  Box,
  styled,
  Drawer,
} from "@mui/material"
import CloseIcon from "@mui/icons-material/Close"
export interface CaretakerProfile {
    first_name: string
    last_name: string
    phone_number: number | string
    gender: string | null
    email: string
    member_id: string
    designation: string
    date_of_birth: string | null
    address1: string | null
    address2: string | null
    state: string | null
    city: string | null
    zipcode: string | null
    account_id: number
    created_at: string
    image: string | File
    page_action?: {
      signup: boolean
      setup_profile: boolean
      child_profile: boolean
    }
    is_parent?: boolean
    account_type?: string
    id:string
  }


const phoneRegExp = /^((\\+[1-9]{1,4}[ -]*)|(\$$[0-9]{2,3}\$$[ -]*)|([0-9]{2,4})[ -]*)*?[0-9]{3,4}?[ -]*[0-9]{3,4}?$/
const zipCodeRegExp = /^[0-9]{5}(?:-[0-9]{4})?$/
const nameRegExp = /^[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z]*)*$/

const DESIGNATION_OPTIONS = [
    { value: "", label: "Designation" },
    { value: "Mother", label: "Mother" },
    { value: "Father", label: "Father" },
    { value: "Step-Mother", label: "Step-Mother" },
    { value: "Step-Father", label: "Step-Father" },
    { value: "Grandmother", label: "Grandmother" },
    { value: "Grandfather", label: "Grandfather" },
  ]
export const caretakerValidationSchema = Yup.object().shape({
    first_name: Yup.string()
      .matches(nameRegExp, "Please enter a valid name")
      .min(2, "Too Short!")
      .max(50, "Too Long!")
      .required("First name is required"),
    last_name: Yup.string()
      .matches(nameRegExp, "Please enter a valid name")
      .min(2, "Too Short!")
      .max(50, "Too Long!")
      .required("Last name is required"),
  phone_number: Yup.string()
    .required("Phone number is required")
    .matches(/^\d+$/, "Only numbers are allowed")
    .length(10, "Phone number must be exactly 10 digits"),

    email: Yup.string().email("Invalid email address").required("Email is required"),
    address1: Yup.string().nullable(),
    address2: Yup.string().nullable(),
    city: Yup.string().nullable(),
    state: Yup.string().nullable(),
    zipcode: Yup.string().matches(zipCodeRegExp, "Please enter a valid ZIP code").nullable(),
    designation: Yup.string().required("Designation is required"),
  })



const StyledAvatar = styled(Avatar)(({ theme }) => ({
  width: theme.spacing(10),
  height: theme.spacing(10),
  margin: "auto",
  marginBottom: theme.spacing(2),
}))

interface CaretakerProfileFormProps {
  open: boolean
  onClose: () => void
  initialData: CaretakerProfile
  subfn:(data:CaretakerProfile)=>void
}

const StyleDrawer = styled(Drawer)(({ theme }) => ({
    "& .MuiDrawer-paper": {
  width: 850,
  borderRadius: '10px 0px 0px 10px',
  flexShrink: 0,
  [theme.breakpoints.down('sm')]: {
    width: 420,
  },
},
    ".menuButton": {
      marginRight: theme.spacing(2),
      
    },
    ".toolbar": {
      ...theme.mixins.toolbar,
      
    },
  }));


export default function CaretakerProfileForm({ open, onClose, initialData,subfn }: CaretakerProfileFormProps) {
    const [imagePreview, setImagePreview] = useState<string|null>(null)
  
    const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      const file = event.target.files?.[0]
      if (file) {
        const reader = new FileReader()
        reader.onloadend = () => {
          setImagePreview(reader.result as string)
        }
        reader.readAsDataURL(file)

      }
    }

    const formatPhoneNumber = (phone_number: string ) => {
      if (!phone_number) return "";
      const onlyNums = phone_number.replace(/\D/g, "");
      if (onlyNums.length <= 3) return onlyNums;
      if (onlyNums.length <= 6) return `${onlyNums.slice(0, 3)}-${onlyNums.slice(3)}`;
      return `${onlyNums.slice(0, 3)}-${onlyNums.slice(3, 6)}-${onlyNums.slice(6, 10)}`;
    };
  
    return (
      <StyleDrawer anchor="right" open={open} onClose={onClose} >
        <Box sx={{ p: 3 }}>
          {/* Header with Close Button */}
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              pb: 2,
              borderBottom: 1,
              borderColor: "divider",
            }}
          >
            <Typography variant="h6" sx={{fontWeight:"bold" , fontSize:'24px'}}>Caretaker Profile</Typography>
            <IconButton onClick={onClose} size="small" data-test-id="close-button">
              <CloseIcon />
            </IconButton>
          </Box>
  
          {/* Form */}
          <Formik
            initialValues={initialData}
            validationSchema={caretakerValidationSchema}
            onSubmit={(values, { setSubmitting }) => {
              console.log("Updated Form Data:", values)
              subfn(values)
              setSubmitting(false)
            }}
            data-test-id="submit-button"
          >
            {({ values, errors, touched, handleChange,setFieldValue, handleBlur, handleSubmit, isSubmitting, setFieldTouched, }) => (
              <Form onSubmit={handleSubmit}>
                {/* Avatar Upload */}
                
                <Box textAlign="center" mb={4} mt={2}>
                   {imagePreview ? 
                <StyledAvatar src={typeof imagePreview === "string" && imagePreview ? imagePreview : "https://via.placeholder.com/150"} />:
                <StyledAvatar src={(values.image).toString()} />}
                <input
                    accept="image/*"
                    style={{ display: "none" }}
                    id="image-upload"
                    type="file"
                    onChange={(e:React.ChangeEvent<HTMLInputElement>)=>{
                      handleImageChange(e)
                      setFieldValue('image',e?.target.files?.[0])
                    }}
                />
                <label htmlFor="image-upload">
                    <Button component="span" size="small">
                    Edit profile picture
                    </Button>
                </label>
                </Box>
  
                {/* Section: Personal Information */}
                <Typography variant="subtitle2" sx={{fontWeight:"bold" , fontSize:'24px'}}>
                  Personal Information
                </Typography>
                <Grid container spacing={2} sx={{mt:0 , mb:2,pt:2 ,pb:2}}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    name="first_name"
                    label="First Name"
                    value={values.first_name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.first_name && Boolean(errors.first_name)}
                    helperText={touched.first_name && errors.first_name}
                    InputProps={{
                      sx: { borderRadius: 3 } 
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    name="last_name"
                    label="Last Name"
                    value={values.last_name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.last_name && Boolean(errors.last_name)}
                    helperText={touched.last_name && errors.last_name}
                    InputProps={{
                      sx: { borderRadius: 3 } 
                    }}
                  />
                </Grid>
                <Grid item xs={12} sx={{py:2}}>
                  <TextField
                    fullWidth
                    select
                    name="designation"
                    label="Designation"
                    value={values.designation}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.designation && Boolean(errors.designation)}
                    helperText={touched.designation && errors.designation}
                    InputProps={{
                      sx: { borderRadius: 3 } 
                    }}
                  >
                    {DESIGNATION_OPTIONS.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                  
                  
                  
                </Grid>
                <Grid item xs={12} sm={6}>

                <TextField
                    fullWidth
                    select
                    name="account_type"
                    label="Account type"
                    value={values.account_type}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.account_type && Boolean(errors.account_type)}
                    helperText={touched.account_type && errors.account_type}
                    InputProps={{
                      sx: { borderRadius: 3 } 
                    }}
                  >
                    <MenuItem key={"primary"} value={"primary"}>
                        Primary Caretaker
                      </MenuItem>
                      <MenuItem key={"secondary"} value={"secondary"}>
                      Secondary Caretaker
                      </MenuItem>
                  </TextField>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                      fullWidth
                      name="phone_number"
                      label="Phone number"
                      value={formatPhoneNumber(values.phone_number.toString())}
                      onChange={(e) => {
                        const onlyNums = e.target.value.replace(/\D/g, "");
                        if (onlyNums.length === 10) {
                          setFieldTouched("phone_number", false);
                        }else if(onlyNums.length > 10){
                          setFieldTouched("phone_number", false);
                        } else {
                          setFieldTouched("phone_number", true);
                        }
                        setFieldValue("phone_number", onlyNums);
                      }}
                      inputProps={{
                        maxLength: 14,
                        inputMode: "numeric",
                        pattern: "\\d{3}-\\d{3}-\\d{4}",
                      }}
                      InputProps={{
                        sx: { borderRadius: 3 }, 
                      }}
                      sx={{ borderRadius: 3 }} 
                      error={touched.phone_number && Boolean(errors.phone_number)}
                      helperText={touched.phone_number && errors.phone_number}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      name="email"
                      label="Email Address"
                      disabled
                      value={values.email}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={touched.email && Boolean(errors.email)}
                      helperText={touched.email && errors.email}
                      InputProps={{
                        sx: { borderRadius: 3 } 
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12}>
                  <Box sx={{  textAlign: "right" }} >
                  <Button fullWidth type="submit" variant="contained" sx={{backgroundColor:"#f15642",width:'100%' ,borderRadius:3}} disabled={isSubmitting} data-test-id="submit-button2">
                    Save Changes
                  </Button>
                </Box>
                </Grid>
                </Grid>
                {/* Submit Button */}
               
              </Form>
            )}
          </Formik>
        </Box>
      </StyleDrawer>
    )
  }