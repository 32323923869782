import React from "react";
// Customizable Area Start
import { Box, Typography, CircularProgress } from "@mui/material";
import ProfileCard from "../../../components/src/ProfileCard";
import { SideBar } from "../../../components/src/SideBar";
import CommonHerderDateinput from "../../../components/src/CommonHerderDateinput"

import StringModal from "../../../components/src/StringModal";
import AgeGroupsAccordion from "../../../components/src/AgeGroupsAccordion";
// Customizable Area End
import DashboardController, {
  GroupedItems,
  Props,
  Reservation,
  webConfigJSON
} from "./DashboardController.web";
import { parseTime } from "../../../components/src/util";

export default class Dashboard extends DashboardController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  async componentDidMount(): Promise<void> {
    this.getLocatations()
    this.getDashboardData()
    this.sendGetAPIRequest(webConfigJSON.getTimeSloth,"apiCallTimeSlothgetCallId");
  }
  componentDidUpdate(prevProps: Props, prevState: any) {

    if (prevState.selectedLocation !== this.state.selectedLocation || prevState.selectedDate !== this.state.selectedDate) {
      this.getDashboardData()
      this.sendGetAPIRequest(webConfigJSON.getTimeSloth,"apiCallTimeSlothgetCallId");
    }
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    // istanbul ignore next
    const data = this.state.reservationData?.upcoming.data;

    const groupedItems: GroupedItems | undefined = data?.reduce((acc: GroupedItems, item: Reservation) => {
      const hours = item.attributes.childrens[0].hour.toString();
      if (!acc[hours]) {
        acc[hours] = [];
      }
      acc[hours].push(item);
      return acc;
    }, {});

    return (
      //Merge Engine DefaultContainer
      <Box width={'100vw'} height={'100vh'} >
        <input
          autoFocus
          ref={this.inputRef}
          type="text"
          onKeyDown={this.handleKeyDown}
          style={{ 
            position: 'absolute', 
            top: '10rem', 
            left: '20rem', 
            color: 'red',
            border: '1px solid red',
            opacity: 0
          }}
          placeholder="Type and press Enter"
        />
        <SideBar selectedPage="ACTIVE_BOOKINGS"  navigateFun={this.navigateFun} 
        tileSlothData={this.state.TimeSlothData}
        SideBarContent={<><AgeGroupsAccordion/></> } 
        updateQRCodeState={(data)=>this.updateQRCodeState(data)}
          data-test-id = "sidebar"
          locations={this.state.locations ||[]}
          HeaderTitel={  <CommonHerderDateinput selectedDate={this.state.selectedDate} setSelectedDate={(e) => {
            this.setState({ selectedDate: e }, () => {
              this.updateLocationState(this.state.selectedLocation);
            });
          }}  />
        } 
        NavigationFunction={this.props.navigation.navigation}
        updateLocationState={this.updateLocationState}
        >
        
        <Box sx={{paddingX:'50px'  , backgroundColor:'#F5F8FA', marignTop:'40px'}}>
         
          {this.state.reservationData ? 
          <Box sx={{display:'flex', gap:'16px' , width:'100%',flexDirection: { xs: 'column', md: 'row' },}}>
              <Box sx={webStyles.Colcont}>
                  <Typography style={webStyles.ColTitelGry}>Drop Off ({this.state.reservationData.upcoming.data.length})</Typography>
                  <Box sx={webStyles.deviderHorizontal}/>
                    {/* istanbul ignore next */}
                    {groupedItems && Object.entries(groupedItems)
                    // istanbul ignore next
                    .sort(([a], [b]) => {
                      // istanbul ignore next
                      return parseTime(a) - parseTime(b);
                    })
                    .map(([hours, items]) => (
                      <Box key={`group-${hours}`}>
                      <h2 style={{fontSize: "14px", fontFamily: "Inter", color: "#535656"}}>
                        {hours}
                      </h2>
                      {items.map((item: Reservation) => (
                        <Box key={item.id} sx={{ marginBottom: "16px" }}>
                        <ProfileCard
                          kidImg={item.attributes.childrens[0].image}
                          kidName={item.attributes.childrens[0].full_name}
                          sideColor={item.attributes.childrens[0].color}
                        />
                        </Box>
                      ))}
                      </Box>
                    )
                  )}
              </Box>
              <Box sx={webStyles.Colcont}>
                <div style={{display:'flex' , justifyContent:'space-between'}}>
                <Typography style={webStyles.ColTitelGry} >
                
                Who’s Here ({this.state.reservationData.ongoing_reservations.data.length})
  
                 </Typography>
                <Typography sx={{ color:'#F1544B' ,display:'inline-block',fontWeight:'bold'}}>{this.state.StaffReq} Staff Required</Typography>
             
                </div>
              <Box sx={webStyles.deviderHorizontal}/>
              <Box>
                {this.state.reservationData.ongoing_reservations.data.map((item)=>{
                    return <Box key={item.id} sx={{marginBottom:'16px'}}>
                      <ProfileCard 
                        kidImg={item.attributes.childrens[0].image} 
                        kidName={item.attributes.childrens[0].full_name} 
                        sideColor={item.attributes.childrens[0].color} 
                      />
                      </Box>
                })}
              </Box>
              </Box>
              <Box sx={webStyles.Colcont}> 
              <Typography style={webStyles.ColTitelGry}>
              Pick Up ({this.state.reservationData.completed.data.length})
              </Typography>
              <Box sx={webStyles.deviderHorizontal}/>
             
              {this.state.reservationData.completed.data.map((item)=>{
                    return <Box key={item.id} sx={{marginBottom:'16px'}}>
                      <ProfileCard 
                        kidImg={item.attributes.childrens[0].image} 
                        kidName={item.attributes.childrens[0].full_name} 
                        sideColor={item.attributes.childrens[0].color} 
                        parentImg={item.attributes.care_taker?.image||""}
                        parentName={item.attributes.care_taker?.full_name}
                      />
                      </Box>
                })}
                            <Typography style={webStyles.ColTitelGry}>
              Running Late ({this.state.reservationData.running_late.data.length})
              </Typography>
              <Box sx={webStyles.deviderHorizontal}/>
             
              {this.state.reservationData.running_late.data.map((item)=>{
                    return <Box key={item.id} sx={{marginBottom:'16px'}}>
                      <ProfileCard 
                        kidImg={item.attributes.childrens[0].image} 
                        kidName={item.attributes.childrens[0].full_name} 
                        sideColor={item.attributes.childrens[0].color} 
                        parentImg={item.attributes.care_taker?.image||""}
                        parentName={item.attributes.care_taker?.full_name}
                      />
                      </Box>
                })}
              </Box>
            </Box>:<CircularProgress color="inherit" />}
 
        </Box>
       </SideBar>
       <StringModal  message={this.state.QrMsg}/>
      </Box>
      //Merge Engine End DefaultContainer
    );
    // Customizable Area End
  }
}

// Customizable Area Start

const webStyles = {
  container: {
    display: "grid",
    gridTemplateColumns: "3fr 7fr",
    boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
    width: "32rem",
    padding: "0.5rem 1rem",
    minHeight: "12rem",
    gap: "2rem"
  },
  totalCandidates: {
    display: "grid",
    gridTemplateRows: "3fr 7fr",
    alignItems: "start",
    justifyContent: "center",
    justifyItems: "center",
    color: "black",
    margin: "1rem 0rem"
  },
  candidatesFontSize: {
    fontSize: "1rem"
  },
  btn: {
    backgroundColor: "blue",
    color: "white",
    borderRadius: "0",
    marginTop: "0.5rem",
    padding: "0.125rem"
  },
  fontSizeForType: { fontSize: "1 rem" },
  mediumFontSizeForType: { fontSize: "3rem" },
  itemStyle: { margin: "1rem 0rem" },
  type: { fontSize: "0.8rem", textTransform: "capitalize" },
  titletext: {
    color: "grey",
    fontWeight: "lighter",
    fontSize: "3rem"
  },
  typeText: { fontSize: "0.8rem", textTransform: "capitalize" },
  Colcont:{flex:1,display:'flex', flexDirection:'column', background:'#ffff'},
  ColTitelGry :{
    fontSize:'18px',
    color:'3D475C',
    background:'#ffff'
  },
  deviderHorizontal:{
    width: '100%',
    height: '1px', 
    backgroundColor: 'rgba(0, 0, 0, 0.1)', 
    marginTop: '9px', 
    marginBottom: '20px', 
  }
  
};
// Customizable Area End
