import React, { useRef, useState } from 'react';
import { Box, Typography, Checkbox, FormControlLabel, Button } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import SignatureCanvas from 'react-signature-canvas';

export default function Step6Terms({ formikProps ,Terms , storeSignatureAPI}: { formikProps: any , Terms :{
    title: string,
    description: string
}[]|null , storeSignatureAPI:any}) {
  const { values, setFieldValue, isSubmitting } = formikProps;
  const [showSignature, setShowSignature] = useState(false);
  const sigCanvas = useRef<any>(null);
  const [signatureData, setSignatureData] = useState(null);
  const termsdescription = Terms??[]

  const saveSignature = () => {
    if (sigCanvas.current) {
      const signatureImage = sigCanvas.current.toDataURL('image/png');
      setSignatureData(signatureImage);
      storeSignatureAPI(signatureImage)
    }
  };

  const clearCanvas = () =>{
    if(sigCanvas){
      sigCanvas.current.clear();
      setFieldValue("signature", false); 
    } 
  }

  if (!showSignature) {
    return (
      <Box>
        <Typography variant="h4" component="h2" sx={{ mb: 1, fontFamily:'KoHo, sans-serif', color:"#000", fontWeight:"500" }}>
          Terms and Conditions
        </Typography>

        <Box sx={{ height: '400px', overflowY: 'auto', mb: 3 }}>
          {termsdescription.map((term, index) => (
            <Box key={index} sx={{ mb: 3 }}>
              <Typography variant="subtitle1" fontWeight="bold" style={{fontFamily:'KoHo, sans-serif', fontSize:"18px"}}>
                {term.title}
              </Typography>
              <Typography variant="body2" color="text.secondary" style={{fontSize:"14px", color:"#535656"}}>
                {term.description}
              </Typography>
            </Box>
          ))}
        </Box>

        <FormControlLabel
          control={
            <Checkbox
              checked={values.acceptTerms}
              onChange={(e) => {
                setFieldValue('acceptTerms', e.target.checked);
                if (e.target.checked) {
                  setShowSignature(true);
                }
              }}
            />
          }
          label="I accept these terms"
        />
      </Box>
    );
  }

  return (
    <Box>
      <Typography variant="h4" component="h2" sx={{ mb: 1, fontFamily:'KoHo, sans-serif', color:"#000", fontWeight:"500" }}>
        Signature
      </Typography>
      <Typography variant="body1" color="text.secondary" sx={{ mb: 4, fontFamily:'KoHo, sans-serif', color:"#535656", fontSize:"18px" }}>
        For added security and to meet legal requirements, we require your signature. This signature will be used for verification, compliance, or other documentation when and where necessary.
      </Typography>

      <Box 
        sx={{ 
          border: '1px solid',
          borderColor: 'divider',
          borderRadius: 1,
          mb: 3
        }}
      >
        <Button onClick={clearCanvas} style={{
          background: "#ff4d4f",
          color: "#fff",
          textTransform: "capitalize",
          display: "flex",
          margin: "8px 8px 0 auto",
          cursor: "pointer",
          fontSize: "16px"
        }}> Clear </Button>
        <SignatureCanvas
         ref={sigCanvas}
          canvasProps={{
            width: 500,
            height: 200,
            className: 'signature-canvas'
          }}
          onEnd={() => setFieldValue('signature', true)}
        />
      </Box>

      <LoadingButton
        fullWidth
        variant="contained"
        type="submit"
        loading={isSubmitting}
        disabled={!values.signature}
        onClick={saveSignature}
        sx={{
          bgcolor: '#ff4d4f',
          padding:"20px 16px",
          fontFamily:"Inter, sans-serif",
          fontWeight:"600",
          borderRadius:'12px',
          textTransform:"capitalize",
          '&:hover': {
            bgcolor: '#ff7875',
          },
        }}
      >
        Next
      </LoadingButton>
    </Box>
  );
}

