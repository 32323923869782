import React from "react";

// Customizable Area Start
import {
  Container,
  Box,
  Button,
  Input,
  Typography,
  InputAdornment,
  IconButton,
  Checkbox,
} from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Visibility from "@material-ui/icons/Visibility";
import { logo, backgroundImage, WelcomeImg } from "./assets"
import { StyleSheet } from "react-native";
import Spinner from './components/Spinner.web';

// Customizable Area End

import EmailAccountLoginController, {
  Props,
} from "./EmailAccountLoginController";

export default class EmailAccountLoginBlock extends EmailAccountLoginController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
 // Customizable Area Start
  PageStyle = {
    inputTextbox: {
    border: '1px solid #D2D2D2',
    borderRadius: '12px',
   padding: '13px 16px '
   
  },
    errorText: { fontFamily: 'Inter sans-serif', color: 'red', paddingTop: '8px', marginTop: '10px', marginRigt: '5px' }
  
 }
 theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});
 // Customizable Area End
  render() {
    return (
      // Customizable Area Start
      // Required for all blocks
      <ThemeProvider theme={this.theme}>
        <Box style={{ 
          backgroundImage: `url(${backgroundImage})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',}}>
        <Container maxWidth="sm" style={{
          height:'100vh',
          display:'flex',
          justifyContent:'center',
          alignItems:'center'
        }}>
          <Box
            sx={{
              width:'440.6px',
              height:'524.71px',
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              padding: "10px 0px",
            }}
          >
            <img src={logo} alt={'recess-logos'} style={{
              width:'100%',
              height:'88px'
            }}/>
              <Box style={{ width:'90%', padding:'0px 20px', marginTop:'78px', display:'flex', flexDirection:'column', alignItems:'center', justifyContent:'center'}}>
                <Typography variant="h4" component="div" style={{ fontFamily: 'KoHo, sans-serif',
                  color: 'black',
                  fontWeight: 600, 
                  fontSize: '50px'
                }}>
                  <img src={WelcomeImg} alt={'welcome'} style={{height:'50px' }}/>
                  
                </Typography>
              

                <Box sx={{ width: "100%", paddingTop: "63px" }}>
                  <Box sx={{ padding: "0px 0px 17px 0px" }}>
                    <Input
                      data-test-id="txtInputEmail"
                      placeholder={"Email Address"}
                      fullWidth={true}
                      value={this.state.email}
                      onChange={(e: any) => this.setEmail(e.target.value)}
                      style={{ ...this.PageStyle.inputTextbox, }}
                      disableUnderline={true}
                      
                    />
                    {!!this.state.emailErr && <span style={this.PageStyle.errorText}>{this.state.emailErr}</span>}
                  </Box>
                  <Box sx={{ padding: "0px" }}>
                    <Input
                      data-test-id="txtInputPassword"
                      type={this.state.enablePasswordField ? "password" : "text"}
                      placeholder={this.state.manager === "" ? 'Staff ID' : 'User ID'}
                      fullWidth={true}
                      value={this.state.password}
                      onChange={(e: any) => this.setPassword(e.target.value)}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={this.handleClickShowPassword}
                            edge="end"
                          >
                            {this.state.enablePasswordField ? (
                              <Visibility />
                            ) : (
                              <VisibilityOff />
                            )}
                          </IconButton>
                        </InputAdornment>
                      }
                      style={{ ...this.PageStyle.inputTextbox, }}
                      disableUnderline={true}
                    />
                   {!!this.state.passError.length && <span style={this.PageStyle.errorText}>{this.state.passError}</span>}

                  </Box>
                
                  <Box
                    sx={{
                     
                      // marginTop: "5px",
                      color: "black",
                      fontSize: "15px",
                      fontFamily:'Inter',
                      marginTop: "20px",

                    }}
                  >
                    <Checkbox
                      data-test-id={"btnRememberMe"}
                      onClick={() =>
                        this.setRememberMe(!this.state.checkedRememberMe)
                      }
                      checked={this.state.checkedRememberMe}
                      
                    />{" "}
                    <span style={{
                      textShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
                      fontFamily: 'Inter, sans-serif',
                    }}>Remember Me</span>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      padding: "10px 0px",
                    }}
                  >
                    <Button
                      data-test-id={"btnEmailLogIn"}
                      variant="contained"
                      style={{ color: '#ffff', background: '#F1544B', padding: "12px  0", fontFamily: 'Inter, sans-serif', fontSize: '15px', textTransform: 'capitalize', borderRadius: '12px', fontWeight: 'bold' }}
                      fullWidth
                      onClick={this.handleLogin}
                    >
                      Login {/*UI Engine::From Sketch*/}
                    </Button>
                       {this.state.loading && <Spinner spinnerModal={this.state.loading} />}
                    <Box
                    data-test-id={"btnForgotPassword"}
                    sx={{
                      fontSize: "15px",
                      color: "#F1544B",
                      fontWeight: "semi-bold",
                      fontFamily: 'Inter, sans-serif',
                      marginTop: "20px",
                    }}
                    onClick={() => this.goToForgotPassword()}
                  >
                    <Typography component={"span"} style={{
                        fontFamily: 'Inter, sans-serif',
                        textDecoration: this.state.manager === "" ? '' : 'underline',
                      }}>{this.state.manager === "" ? 'Forgot Staff ID' : 'Forgot User ID'}</Typography>
                    </Box>
                    <Box
                      sx={{
                        fontSize: "18px",
                        color: "#3DB3E4",
                        fontWeight: "bold",
                        fontFamily: 'Inter, sans-serif',
                        marginTop: "20px",

                      }}
                      onClick={() => this.props.navigation.navigate("EmailAccountRegistration")}
                    >
                      <span style={{
                        // textShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
                      }}>{this.state.manager === "" ? '' : 'Sign Up'}</span>
                  </Box>
                  </Box>
                  
                </Box>
              </Box>
          </Box>
        </Container>
        </Box>
      </ThemeProvider>
       // Customizable Area End
    );
  }

}

const styles = StyleSheet.create({
  main: {
    display: "flex",
    alignItems: "center"
  },
  body: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "10px",
    width: "50%",
  },
  textFont: {
    fontFamily: "Helvetica"
  },
  formWrapper: {
    display: "flex",
    width: "100%",
    paddingTop: "20px" 
  },
  inputWrapper: {
    flex: 1,
    padding: "20px"
  },
  removeBackground: {
    backgroundColor: "transparent" 
  },
  checkBoxText: {
    fontSize: 18,
    color: "#6200EE",
    fontWeight: "bold",
  },
  buttonsWrapper: {
    flex: 1,
    padding: "10px",
  },
  OrWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "10px",
  }
});
