import  React from "react"
import  { FormikProps } from "formik"
import {
  Box,
  TextField,
  Typography,
  RadioGroup,
  Radio,
  FormControlLabel,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Avatar,
  Button,
} from "@mui/material"
import  { FormValues } from "./AddKId"
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers"
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns"
import moment from "moment"

interface ProfileStepProps {
  handleReEnroll: () => void;
  formikProps: FormikProps<FormValues>;
  isEnrolled:boolean
  
}

export default function ProfileStep({ formikProps, handleReEnroll, isEnrolled}: ProfileStepProps) {
  const { values, errors, touched, handleChange, setFieldValue ,setFieldTouched} = formikProps

  const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0]
    if (file) {
      setFieldValue("profileImage", file)
    }
  }

  const handleZipcodeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    const numericValue = value.replace(/\D/g, "");
    if (numericValue.length <= 5) {
      setFieldValue("zipcode", numericValue);
    }
  };
console.log("formikProps",values)

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 3 }}>
      {/* Profile Image Upload */}
      <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", gap: 1 }}>
        <Avatar
          src={values.profileImage ? URL.createObjectURL(values.profileImage) : ""}
          sx={{ width: 100, height: 100, mb: 1 }}
        />
        <input
          accept="image/*"
          type="file"
          id="profileImage"
          name="profileImage"
          onChange={handleImageChange}
          style={{ display: "none" }}
        />
        <label htmlFor="profileImage">
          <Typography
            component="span"
            variant="body2"
            sx={{
              cursor: "pointer",
              textDecoration: "underline",
              color: "primary.main",
            }}
          >
            Edit profile
          </Typography>
        </label>

        {values.isEdit&& !isEnrolled &&<Button type="button" onClick={handleReEnroll} style={{color:"#F89F34", textDecoration:"underline", fontSize:"16px", fontWeight:"600", textTransform:"capitalize"}}> Re-enroll </Button>}
      
          <Typography color="error" variant="caption">
            { touched.lastName && errors.lastName?.includes('image')? errors.lastName :""}
          </Typography>
        
      </Box>
      <Typography variant="subtitle1" sx={{fontWeight:'bold'}}>Child Information</Typography>
      {/* Name Fields */}
      <Box sx={{ display: "flex", gap: 2 }}>
        <TextField
          fullWidth
          sx={{borderRadius:3}}
          id="firstName"
          name="firstName"
          label="First name"
          value={values.firstName}
          onChange={handleChange}
          error={touched.firstName && Boolean(errors.firstName)}
          helperText={touched.firstName && errors.firstName}
          InputProps={{
            sx: { borderRadius: 3 } 
          }}
        />
        <TextField
          fullWidth
          id="lastName"
          name="lastName"
          label="Last Name"
          value={values.lastName}
          onChange={handleChange}
          error={(touched.lastName && !values.lastName) && Boolean(!errors.lastName?.includes('img'))}
          helperText={(touched.lastName && !values.lastName) && (errors.lastName?.includes('profile')?"":"last Name is required")}
          InputProps={{
            sx: { borderRadius: 3 } 
          }}
        />
      </Box>

      {/* Gender Selection */}
      <Box sx={{ display: "flex", gap: 2 }}>
      <FormControl fullWidth>
        <RadioGroup
          row
          name="gender"
          value={values.gender}
          onChange={handleChange}
          sx={{ ml: 1.2, gap: 0.8 }}
        >
          {["Male", "Female", "other"].map((option) => (
            <FormControlLabel
              key={option}
              value={option}
              control={<Radio sx={{ display: "none", visibility:'hidden' ,width:0, height:'0', bgcolor:'transparent', border:'none' }} />}
              label={option === "other" ? "Not Specified" : option}
              sx={{
                backgroundColor: values.gender === option ? "primary.main" : "white",
                color: values.gender === option ? "white" : "gray",
                border: "1px solid",
                borderColor: values.gender === option ? "primary.main" : "gray",
                borderRadius: 3,
                px: 3.2,
                py: 2,
                textAlign: "center",
                fontSize: 16,
                fontWeight: 500,
                cursor: "pointer",
                transition: "all 0.3s",
                "&:hover": {
                  backgroundColor: values.gender === option ? "primary.dark" : "grey.200",
                },
              }}
            />
          ))}
        </RadioGroup>
      </FormControl>

     
      <FormControl fullWidth>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            label="Birthday"
            name="birthday"
            value={values.birthday ? new Date(values.birthday) : null}
            onChange={(newValue: Date | null) => {
              setFieldValue("birthday", newValue ? moment(newValue).format("YYYY-MM-DD") : null);
            }}
            onClose={() => setFieldTouched("birthday", true)} 
            slotProps={{
              textField: {
                fullWidth: false,
                error: Boolean(touched.birthday) && Boolean(values?.birthday.includes("i")),
                helperText: Boolean(touched.birthday) && (Boolean(values?.birthday.includes("i"))&& values?.birthday),
                InputProps:{
                  sx: { borderRadius: 3 } 
                }
              },
            }}
            disableFuture
          />
        </LocalizationProvider>
      </FormControl>
      </Box>
                          

      {/* Address Fields */}
      <Typography variant="subtitle1" sx={{fontWeight:'bold'}}>Primary Address</Typography>
      <TextField
        fullWidth
        id="addressLine1"
        name="addressLine1"
        label="Address line 1"
        value={values.addressLine1}
        onChange={handleChange}
        error={touched.addressLine1 && Boolean(errors.addressLine1)}
        helperText={touched.addressLine1 && errors.addressLine1}
        InputProps={{
          sx: { borderRadius: 3 } 
        }}
      />
      <TextField
        fullWidth
        id="addressLine2"
        name="addressLine2"
        label="Address line 2"
        value={values.addressLine2}
        onChange={handleChange}
        error={touched.addressLine2 && Boolean(errors.addressLine2)}
        helperText={touched.addressLine2 && errors.addressLine2}
        InputProps={{
          sx: { borderRadius: 3 } 
        }}
      />
       <Box sx={{ display: "flex", gap: 2 }}>
      <TextField fullWidth id="apt" name="apt" label="Apt (optional)" value={values.apt} onChange={handleChange}  InputProps={{
                      sx: { borderRadius: 3 } 
                    }} />
      <TextField
        fullWidth
        id="city"
        name="city"
        label="City"
        value={values.city}
        onChange={handleChange}
        error={touched.city && Boolean(errors.city)}
        helperText={touched.city && errors.city}
        InputProps={{
          sx: { borderRadius: 3 } 
        }}
      />
      </Box>

      <Box sx={{ display: "flex", gap: 2 }}>
        <TextField
          fullWidth
          id="state"
          name="state"
          label="State"
          value={values.state}
          onChange={handleChange}
          error={touched.state && Boolean(errors.state)}
          helperText={touched.state && errors.state}
          InputProps={{
            sx: { borderRadius: 3 } 
          }}
        />
        <TextField
          fullWidth
          id="zipcode"
          name="zipcode"
          label="Zipcode"
          value={values.zipcode}
          onChange={handleZipcodeChange}
          error={touched.zipcode && Boolean(errors.zipcode)}
          helperText={touched.zipcode && errors.zipcode}
          InputProps={{
            sx: { borderRadius: 3 } 
          }}
        />
      </Box>

      {/* Legal Custody and Language */}
      <Typography variant="subtitle1" sx={{fontWeight:'bold'}}>Child Notes</Typography>
      <FormControl fullWidth error={touched.legalCustody && Boolean(errors.legalCustody)}>
        
        <TextField
          id="legalCustody"
          name="legalCustody"
          label="Custody"
          value={values.legalCustody}
          onChange={handleChange}
          error={touched.legalCustody && Boolean(errors.legalCustody)}
          helperText={touched.legalCustody && errors.legalCustody}
          InputProps={{
            sx: { borderRadius: 3 } 
          }}
        />
       
      </FormControl>

      <FormControl fullWidth error={touched.language && Boolean(errors.language)}>
       
          
        <TextField
          
          id="language"
          name="language"
          label="Language"
          value={values.language}
          onChange={handleChange}
          error={touched.language && Boolean(errors.language)}
          helperText={touched.language && errors.language}
          InputProps={{
            sx: { borderRadius: 3 } 
          }}
        />
        
        
      </FormControl>
    </Box>
  )
}

