import React, { useState, useRef } from "react";
import dayjs, { Dayjs } from "dayjs";
import utc from "dayjs/plugin/utc";
import { Box, Typography, IconButton } from "@mui/material";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

dayjs.extend(utc);

type MuiDatePickerProps = {
  selectedDate: string | null;
  setSelectedDate: (date: string) => void;
};

const CommonHerderDateInput: React.FC<MuiDatePickerProps> = ({ selectedDate, setSelectedDate }) => {
  const [open, setOpen] = useState(false);
  const parseDate = (date: string | null): Dayjs => {
    if (!date) return dayjs(); // Default to current date

    const parsed = dayjs.utc(date, "DD-MM-YYYY", true);
    return parsed.isValid() ? parsed : dayjs(); // Default to today if invalid
  };

  const [currentDate, setCurrentDate] = useState<Dayjs>(() => {
    const storedDate = localStorage.getItem("selectedDate");
    return parseDate(storedDate || selectedDate);
  });

  const datePickerRef = useRef<HTMLDivElement | null>(null);

  const handleChange = (date: Dayjs | null) => {
    if (!date) return;
    const formattedDate = date.format("DD-MM-YYYY");

    if (formattedDate !== currentDate.format("DD-MM-YYYY")) {
      localStorage.setItem("selectedDate", formattedDate);
      setCurrentDate(date);
      setSelectedDate(formattedDate);
    }
  };

  const openDatePicker = () => {
    if (datePickerRef.current) {
      const button = datePickerRef.current.querySelector("button");
      if (button) button.click(); 
    }
  };

  return (
    <Box display="flex" gap={0} alignItems="center">
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <label
          style={{ display: "flex", gap: "10px", alignItems: "center", cursor: "pointer" }}
          onClick={openDatePicker} 
        >
          <div ref={datePickerRef}>

            <DatePicker
              value={currentDate}
              onChange={(newValue) => {
                handleChange(newValue);
                setOpen(false); // Close after selecting a date
              }}
              open={open} // Controls whether DatePicker is open
              onClose={() => setOpen(false)}
              slotProps={{
                textField: {
                  sx: { display: "none" }, // Hides the input field
                },
                popper: {
                  modifiers: [
                    {
                      name: "offset",
                      options: {
                        offset: [320, 80],
                      },
                    },
                  ],
                },

              }}
            />
            <img
              onClick={() => setOpen(true)}
              style={{
                height: 30,
                width: 30,
                cursor: "pointer",
                position: "relative",
                zIndex: 10, // Ensures the image is above other elements
              }}
              src={require("./Calendar.png")}
              alt="Calendar Icon"
            />
          </div>
          <Typography variant="h6" fontWeight="semibold" fontFamily="Inter" fontSize="28px">
            {currentDate.format("ddd, MMMM DD YYYY")}
          </Typography>
        </label>

        
      </LocalizationProvider>
    </Box>
  );
};

export default CommonHerderDateInput;
