import React from 'react';
import { Box, TextField, MenuItem, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { DatePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

export default function Step1Profile({ formikProps }: { formikProps: any }) {
  const { values, errors, touched, handleChange, setFieldValue, setFieldTouched, isSubmitting, setFieldError } = formikProps;
  const formatPhoneNumber = (phone: string) => {
    if (!phone) return "";
    const onlyNums = phone.replace(/\D/g, "");
    if (onlyNums.length <= 3) return onlyNums;
    if (onlyNums.length <= 6) return `${onlyNums.slice(0, 3)}-${onlyNums.slice(3)}`;
    return `${onlyNums.slice(0, 3)}-${onlyNums.slice(3, 6)}-${onlyNums.slice(6)}`;
  };
  return (
    <Box>
      <Typography variant="h4" component="h2" sx={{ mb: 1 , fontFamily: 'Koho, sans-serif !important', fontWeight:"500"}}>
        Set Up Your Profile
      </Typography>
      <Typography variant="body1" color="text.secondary" sx={{ mb: 4, fontFamily: 'Koho, sans-serif !important', color:"#000", fontSize:"18px" }}>
        Provide all of the below information related to yourself and designation to the children you'll enroll.
      </Typography>

      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
        <TextField
          fullWidth
          name="firstName"
          label="First name"
          value={values.firstName}
          onChange={handleChange}
          error={touched.firstName && Boolean(errors.firstName)}
          helperText={touched.firstName && errors.firstName}
          sx={{
            "& .MuiOutlinedInput-root": {
              borderRadius: "12px",
              fontFamily:"Inter, sans-serif",
              fontWeight:"regular",
              borderColor: "#ff9900"
            },

            '& .MuiInputLabel-root': { color: '#c4c4c4' }, 
            '& .MuiInputLabel-root.Mui-focused': { color: '#1976d2' }, 
            
          }}
        />

        <TextField
          fullWidth
          name="lastName"
          label="Last name"
          value={values.lastName}
          onChange={handleChange}
          error={touched.lastName && Boolean(errors.lastName)}
          helperText={touched.lastName && errors.lastName}
          sx={{
            "& .MuiOutlinedInput-root": {
              borderRadius: "12px",
              fontFamily:"Inter, sans-serif",
              fontWeight:"regular",
               borderColor: "#c4c4c4"
            },
            

          '& .MuiInputLabel-root': { color: '#c4c4c4' }, 
          '& .MuiInputLabel-root.Mui-focused': { color: '#1976d2' },
          }}
        />

        <Box sx={{ display: 'flex', gap: 2 }}>
          <TextField
            fullWidth
            name="email"
            label="Email address"
            type="email"
            value={values.email}
            onChange={handleChange}
            error={touched.email && Boolean(errors.email)}
            disabled={true}
            helperText={touched.email && errors.email}
            sx={{
              "& .MuiOutlinedInput-root": {
                borderRadius: "12px",
                fontFamily:"Inter, sans-serif",
                fontWeight:"regular",
                borderColor: "#ff9900"
              },
  
              '& .MuiInputLabel-root': { color: '#c4c4c4' }, 
              '& .MuiInputLabel-root.Mui-focused': { color: '#1976d2' },
            }}
          />
          <TextField
            fullWidth
            name="phone"
            label="Phone number"
            value={formatPhoneNumber(values.phone)}
            onChange={(e) => {
              const onlyNums = e.target.value.replace(/\D/g, "");
              if (onlyNums.length === 10) {
                setFieldTouched("phone", false);
              } else {
                setFieldTouched("phone", true);
              }
              setFieldValue("phone", onlyNums);
            }}
            inputProps={{
              maxLength: 12,
              inputMode: "numeric",
              pattern: "\\d{3}-\\d{3}-\\d{4}"
            }}
            error={touched.phone && Boolean(errors.phone)}
            helperText={touched.phone && errors.phone}
            sx={{
              "& .MuiOutlinedInput-root": {
                borderRadius: "12px",
                fontFamily:"Inter, sans-serif",
              fontWeight:"regular",
              borderColor: "#ff9900"
            },

            '& .MuiInputLabel-root': { color: '#c4c4c4' }, 
            '& .MuiInputLabel-root.Mui-focused': { color: '#1976d2' },
            }}
          />

        </Box>
        <TextField
          select
          fullWidth
          disabled
          name="designation"
          label="Designation"
          value={values.designation}
          onChange={handleChange}
          error={touched.designation && Boolean(errors.designation)}
          helperText={touched.designation && errors.designation}
          sx={{
            "& .MuiOutlinedInput-root": {
              borderRadius: "12px",
              fontFamily:"Inter, sans-serif",
              fontWeight:"regular",
              borderColor: "#ff9900"
            },

            '& .MuiInputLabel-root': { color: '#c4c4c4' }, 
            '& .MuiInputLabel-root.Mui-focused': { color: '#1976d2' },
          }}
        >
          <MenuItem value="parent">Parent</MenuItem>
          
        </TextField>
        <LocalizationProvider dateAdapter={AdapterDateFns}>

          <DatePicker
            label="Birthday"
            value={values.birthday}
            onChange={(value) => setFieldValue('birthday', value)}
            disableFuture
            slotProps={{
              textField: {
                fullWidth: true,
                error: Boolean(formikProps.touched.birthday && formikProps.errors.birthday),
                helperText: formikProps.touched.birthday && formikProps.errors.birthday ? formikProps.errors.birthday : '',
              },
              
            }}
            sx={{
              "& .MuiOutlinedInput-root": {
                borderRadius: "12px",
                fontFamily:"Inter, sans-serif",
                fontWeight:"regular",
                borderColor: "#ff9900"
              },
  
              '& .MuiInputLabel-root': { color: '#c4c4c4' }, 
              '& .MuiInputLabel-root.Mui-focused': { color: '#1976d2' },
            }}
          />
        </LocalizationProvider>

        <LoadingButton
          fullWidth
          variant="contained"
          type="submit"
          loading={isSubmitting}
          sx={{
            mt: 2,
            bgcolor: '#ff4d4f',
            borderRadius: "12px",
            padding:"20px 16px",
            fontFamily:"Inter, sans-serif",
            textTransform:"capitalize",
            fontWeight:"600",
            '&:hover': {
              bgcolor: '#ff7875',
            },
            
          }}
        >
          Next
        </LoadingButton>
      </Box>
    </Box>
  );
}

