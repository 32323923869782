import React from "react";
// Customizable Area Start
import {
  createTheme,
  Theme,
} from "@mui/material/styles";
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import AddIcon from '@material-ui/icons/Add';
import AddChild from "../../../components/src/AddChild";
import AddParent from "../../../components/src/AddParent";

import CaretakerProfileForm from "../../../components/src/CaretakerUpdate";
import {AddKId} from "../../../components/src/AddKId";
import "@mui/styles";
import Step4ACH from "../../../components/src/Step4ACH";
import Step5Verify from "../../../components/src/Step5Verify";
import Step6message from "../../../components/src/Step6message";
import CongoKid from "../../../components/src/CongoKid";
import { Form, Formik } from "formik";
import CloseIcon from '@material-ui/icons/Close';
// Customizable Area End

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});

import CustomisableUserProfilesController, {
  Props,
  IField,
} from "./CustomisableUserProfilesController";
import { Box, Container, Typography, Grid, styled, Divider, Drawer } from "@material-ui/core";
import { Alert, ProfileImg, imageArray, imageArray1, imageArray2, imageArray3 } from "./assets";
declare module "@mui/styles/defaultTheme" {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme { }
}

const drawerWidth = 850;

export const CustomDrawer = styled(Drawer)(({ theme }) => ({
  "& .MuiDrawer-paper": {
    width: drawerWidth,
    // height: 'auto',
    zIndex:2,
    flexShrink: 0,
    borderRadius: '10px 0px 0px 10px',
    [theme.breakpoints.down('sm')]: {
      width: 420,
    },
  },
  ".menuButton": {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  ".toolbar": {
    ...theme.mixins.toolbar,
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
}));


// Customizable Area End

export default class CustomisableUserProfiles extends CustomisableUserProfilesController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }


  render() {
    // Customizable Area Start
    return (
      <Box style={{ padding: "10px 25px" }}>
       
        <Box>
        <Grid container>
        <Grid item xs={4}>
          <Typography style={Styles.mainText}>Hi {this.state?.getParentsUserData?.[0]?.attributes?.first_name},</Typography>
          </Grid>
        </Grid>
          <Typography style={Styles.childText}>Welcome to Recess! On this screen you can manage enrollment and or manage associated caretakers.</Typography>
        </Box>
        <Box style={{ margin: '30px 0px', backgroundColor: 'rgb(243 172 168)', display: 'flex', padding: '10px', borderRadius: '10px' }}>
          <img style={{ width: '24px', height: '24px', margin: "0px 10px" }} src={Alert} />
          <Typography style={Styles.child1Text}>Must have a child and backup/secondary caretaker on file in order to book a reservation. Backup/secondary caretakers will act as emergency contacts in the event you are unreachable.</Typography>
        </Box>
        <Box style={{ margin: '50px 0px', padding: '10px' }}>
          <Typography style={Styles.Enrollment}>Enrollments</Typography>
          <Box>
            <Box boxShadow={3}
              bgcolor="background.paper"
              style={{ padding: '20px', display: 'flex', borderRadius: '15px 15px 0px 0px' }}>
              
              <Grid container spacing={4}  data-test-id="ctmappert">
                {this.state.getChildData?.data ? this.state.getChildData?.data?.map((data: any) => (
                    <React.Fragment  data-test-id ="ctitem">
                      <Grid item lg={2} md={2} sm={2}>
                      <div style={{display:'flex', alignItems:'center' ,justifyContent:'left'}}>
                      <img style={{ width: '40px', height: '40px', margin: "0px 10px" , borderRadius:'50%' }} src={data.attributes.child_profile_image ? data.attributes.child_profile_image : ProfileImg} />
                      <Typography style={{ textTransform: 'capitalize' as 'capitalize' }}>{data.attributes.first_name} {data.attributes.last_name}</Typography>
                      
                      </div>
                      </Grid>
                     
                      <Grid item lg={2} md={2} sm={2} style={{display:'flex', alignItems:'center' ,justifyContent:'left'}}> <Typography>{({ Male: 'Son', Female: 'Daughter' }[data.attributes.gender as 'Male' | 'Female'] || '')}</Typography>
                      </Grid>
                      <Grid item lg={4} md={4} sm={2} style={{display:'flex', alignItems:'center' ,justifyContent:'left'}}><Typography>{this.calculateAge(data.attributes.date_of_birth)} years</Typography></Grid>
                      <Grid item lg={2} md={2} sm={2} style={{display:'flex', alignItems:'center' ,justifyContent:'left'}}> <Typography style={{padding:'2px 8px' , borderRadius:'10px' , background: data.attributes?.enrolled ? '#008000' : '#F89F34', color:'#ffff'}}>{data.attributes?.enrolled ? "Enrolled" : "Unenrolled" }</Typography>
                       </Grid>
                      {this.state.userProfileDetails.attributes.designation === "parent" && <Grid item lg={2} md={2} sm={2} style={{display:'flex', alignItems:'center' ,justifyContent:'right'}} 
                      data-test-id ="openctfo"
                      >
                        {this.state.userProfileDetails.attributes.designation === "parent" &&
                          <ArrowForwardIosIcon style={{ cursor: "pointer" }} onClick={() => this.toggleDrawer2(data , data.id , data.attributes.subscription_id)} data-test-id="toggleDrawerBtn" />}
                      </Grid>}
                    </React.Fragment>
                  ))
                  :
                  <Typography>No data </Typography>
                }

              </Grid>

            </Box>
            {this.state.userProfileDetails.attributes.designation === "parent" &&  <Box boxShadow={3}
              bgcolor="background.paper"
              style={Styles.box2_paper}>
              <Grid container style={{ display: 'flex', alignItems: 'center' }}>
                <Grid item lg={6} md={6} sm={6}>
                  <div style={{display:'flex'}}>
                  <img style={Styles.profileImg} src={ProfileImg} />
                  <Box data-test-id="toggleDrawer" onClick={(event) => this.toggleDrawer(event)} style={{ display: 'flex', cursor: 'pointer', alignItems: 'center' }}>
                    <Typography style={Styles.EnrollChild}>Enroll Child</Typography>
                    <AddIcon style={{ margin: '3px 5px' }} />
                  </Box>
                  </div>
                </Grid>
               
              </Grid>
            </Box>}


          </Box>
        </Box>

        <Box style={{ margin: '50px 0px', padding: '10px' }}>
          <Typography style={Styles.Enrollment}>Caretakers</Typography>
          <Box>
            <Box boxShadow={3}
              bgcolor="background.paper"
              style={{ padding: '20px', display: 'flex', borderRadius: '15px 15px 0px 0px' }}>
              <Grid container spacing={4}  data-test-id="ctmappert">
                {this.state.getParantData.data && this.state.getParantData.data.length > 0 ?
                  this.state.getParantData?.data?.map((data: any) => (
                    <React.Fragment  data-test-id ="ctitem">
                      <Grid item lg={2} md={2} sm={2}>
                      <div style={{display:'flex', alignItems:'center' ,justifyContent:'left'}}>
                        <img style={{ width: '40px', height: '40px', margin: "0px 10px" , borderRadius:'50%' }} src={data.attributes.image ? data.attributes.image : ProfileImg} />
                        <Typography style={{ textTransform: 'capitalize' as 'capitalize' }}> {data.attributes.first_name} {data.attributes.last_name}</Typography>
                      </div>
                      </Grid>
                      <Grid item lg={2} md={2} sm={2} style={{display:'flex', alignItems:'center' ,justifyContent:'left'}}><Typography>{data.attributes.account_type}</Typography></Grid>
                      <Grid item lg={2} md={2} sm={2} style={{display:'flex', alignItems:'center' ,justifyContent:'left'}}><Typography>{data.attributes.designation}</Typography></Grid>
                      <Grid item lg={3} md={3} sm={1} style={{display:'flex', alignItems:'center' ,justifyContent:'left'}}><Typography>{data.attributes.email}</Typography></Grid>
                      <Grid item lg={2} md={2} sm={2} style={{display:'flex', alignItems:'center' ,justifyContent:'left'}}><Typography>{data.attributes.phone_number}</Typography></Grid>
                      {this.state.userProfileDetails.attributes.designation === "parent" && <Grid item lg={1} md={1} sm={2} style={{display:'flex', alignItems:'center' ,justifyContent:'right'}} 
                       onClick={() => this.handelCtdrwOpen(data)} 
                      data-test-id ="openctfo"
                      >
                        <ArrowForwardIosIcon style={{ cursor: 'pointer' }}  />
                      </Grid>}
                    </React.Fragment>
                  ))
                  :
                  <Typography>No data </Typography>
                }

              </Grid>

            </Box>
           {this.state.userProfileDetails.attributes.designation === "parent" && <Box boxShadow={3}
              bgcolor="background.paper"
              style={{ padding: '20px', display: 'flex', backgroundColor: 'F1F1F1', borderRadius: '0px 0px 15px 15px' }}>
              <Grid container style={{ display: 'flex', alignItems: 'center' }}>
                <Grid item lg={6} md={6} sm={6}>
                  <div style={{display:'felx'}}>
                 
                  <Grid onClick={(event) => this.toggleDrawerParent(event)} item lg={10} md={10} sm={10} style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
                  <img style={{ width: '40px', height: '40px', margin: "0px 10px" , borderRadius:'50%'  }} src={ProfileImg} />
                  <Typography style={Styles.EnrollChild}>Add Caretakers</Typography>
                  <AddIcon style={{ margin: '3px 5px' }} />
                </Grid>
                  </div>
                
                </Grid>
                
              </Grid>
            </Box>}


          </Box>
        </Box>
       
        <CustomDrawer
          data-test-id="CustomDrawerCom"
          anchor="right"
          open={this.state.open}
          onClose={this.toggleDrawer}
        >
           <div style={{  cursor: 'pointer',
          position: 'fixed',
            fontFamily:'inter',
          top: '20px', 
          right: '20px', 
          fontSize: '30px',}}
          onClick={this.handelDrawerfn}
          ><CloseIcon/></div>
          <AddKId allergens={this.state.AlergentDataArray} 
                      pottyTrainingOptions={this.state.pottyTranaingDataArray}  
                      solidAndPureeOptions={this.state.pureDataArray}
                      napOptions={this.state.NapsDataArray}
                      kidProfileId={this.state.formData.id}
                      submitFn={this.handelMassiveUpdatefn}
                      kidinival={this.state.KidformVals}
                      ReasonText={this.state.ReasonText}
                      DeleteText={this.state.DeleteText}
                      deletedProfileMessage={this.state.deletedProfileMessage}
                      setReasonText={this.handleTextChange}
                      setDeleteText={this.handleDeleteChange}
                      handleUnenroll={this.handleUnenroll}
                      handleDeleteProfile={this.handleDeleteProfile}
                      unenrollMessage={this.state.unenrollMessage}
                      childernId={this.state.childernId}
                      handelDrawerfn={this.handelDrawerfn}
                      data-test-id="AddKId"
                  />
        </CustomDrawer>
        <CongoKid imageSrc={this.state.KidformVals.profileImage} open={this.state.confirmationMoadlSwitch} onClose={()=>{this.toggalConfiramtonSwitch(); this.getChildProfileData()}} handleAddChild={() => {
          this.toggalConfiramtonSwitch()
          this.toggleDrawer("")} }/>
        <CustomDrawer
          data-test-id="CustomDrawerCom"
          anchor="right"
          open={this.state.openParent}
          onClose={this.toggleDrawerParent}
        >
          <div />
          <Divider />
          <AddParent data-test-id="CustomDrawer" 
          handleCloseDialog={this.handleCloseDialog} 
          InviteSuccess={this.state.InviteSuccess} selected={this.state.selected} 
          handleButtonClick={this.handleButtonClick} 
          getAllSolidandPureesData={this.getAllSolidandPureesData} 
          getAllNapsData={this.getAllNapsData} 
          getPottyTrainingData={this.getPottyTrainingData} 
          get_allergenProfileData={this.get_allergenProfileData} 
          postNotesgData={this.postNotesgData} 
          UpDateChildProfile={this.UpDateChildProfile} 
          toggleDrawer2={this.state.toggleDrawer2} 
          open2={this.state.open2} 
          postPottyTrainingData={this.postPottyTrainingData}
           imageSelect={this.state.imageSelect} 
           handleBoxClick={this.postAllergendData}
            postEnrollChildData={this.parantInvite} 
            handleChange={this.handleFormChangeParant}
             handleFormChange2={this.handleFormChange2}
             profileImageEdit={this.state.profileImageEdit} 
             formData={this.state.formDataParant} 
             imageArray={this.state.allergen} 
             imageArray1={this.state.pottyTraining} 
             imageArray2={this.state.getAllNap} 
             imageArray3={this.state.getSolidandPurees} 
             DrawerClose={this.toggleDrawerParent} />
        </CustomDrawer>
        
       { this.state.userProfileDetails.attributes.designation === "parent" &&<CustomDrawer
          data-test-id="payment-drawer"
          anchor="right"
          open={this.props?.paymentDrawer}
          onClose={()=>{
            this.setState({bankingDetailsConformation:false})
            this.props?.closeDrawer()
            this.props?.backToHome()
          }}
          
        >
         
          <CloseIcon data-test-id="payment-close-icon" onClick={()=>{this.props?.closeDrawer()
             this.props?.backToHome()
          }} style={{ position: "absolute", right: "70px", top:"75px", color: '000000', cursor: 'pointer' }} />
           <Formik
                    initialValues={this.state.bankingDetails}
                    validate={this.validationFunctions[3]}
                    data-test-id = 'formik'
                    onSubmit={(values, { setSubmitting }) => {
                      const { bankingDetailsConformation, bankingDetailVerify } = this.state;
                      const { closeDrawer, backToHome } = this.props;
                    
                      if (bankingDetailsConformation || bankingDetailVerify) {
                        closeDrawer?.();
                        bankingDetailVerify && backToHome?.();
                      } else {
                        this.updateBankDetails(values);
                        setSubmitting(false);
                      }
                    }}
                    enableReinitialize={true} 
                  >
                     
                    {(formikProps) => (
                      <Form style={{padding:"70px"}}>
                      
                      {
                        this.state.bankingDetailsConformation?
                        <Step5Verify formikProps={formikProps} showmassege = {this.state.showsuccessmassege}/>
                        :(this.state.bankingDetailVerify) 
                        ? <Step6message formikProps={formikProps} showError = {this.state.showErrorMassege}/> 
                        :<Step4ACH formikProps={formikProps} bankingDetails={this.state.bankingDetails} Conformationmassege={this.state.Conformationmassege}/>
                      }

                      </Form>
                    )}
                  </Formik>

        </CustomDrawer> }       
        <CaretakerProfileForm 
        data-test-id = "ctform"
        open={this.state.openCtcrw} 
        onClose={()=>this.handelCtdrwClose()}  initialData={this.state.caretakerProfile} subfn={(e)=>this.handelCaretakerUpdate(e)}/>
      </Box>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const Styles = {
  mainText: {
    fontSize: '40px',
    fontWeight: 600,
    textTransform: 'capitalize' as 'capitalize',
  },
  childText: {
    fontSize: '16px',
    fontWeight: 400
  },
  child1Text: {
    fontSize: '16px',
    fontWeight: 400
  },
  Enrollment: {
    fontSize: '20px',
    fontWeight: 600,
    marginBottom: '15px',
  },
  EnrollChild: {
    display: 'flex'
  },
  box2_paper: {
    padding: '20px',
    display: 'flex',
    backgroundColor: 'F1F1F1',
    borderRadius: '0px 0px 15px 15px'
  },
  profileImg: {
    width: '40px',
    height: '40px',
    margin: "0px 10px",
    borderRadius:'50%' ,
  }
}
// Customizable Area End
