import  React from "react"
import type { FormikProps } from "formik"
import { Box, Typography, Grid, TextField, Button, CardContent, Card, styled } from "@mui/material"
import { format } from "date-fns"
import { Allergen, PottyTraining, SolidAndPuree, NapOption, FormValues } from "./AddKId"
import FilePreview from "./FilePreview"

interface KidStripeScreenProps {
  formikProps: FormikProps<FormValues>
  
}

export default function KidStripeScreen({
  formikProps,
}: KidStripeScreenProps) {
  const { values, setFieldValue, errors, touched } = formikProps


  const StyledCard = styled(Card)(({ theme }) => ({
    
    margin: "auto",
    textAlign: "center",
    padding: theme.spacing(2),
  }))
  
  const ImageWrapper = styled(Box)(({ theme }) => ({
    width: 120,
    height: 120,
    margin: "0 auto",
    marginBottom: theme.spacing(2),
    borderRadius: "5%",
    overflow: "hidden",
    
  }))

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 4 }}>
      
   

     
      {/* Special Notes Section */}
      <StyledCard>
      <CardContent>
        <ImageWrapper>
         
        <img
        src={values.profileImage instanceof File ? URL.createObjectURL(values.profileImage) : values.profileImage ?? ""}
        alt="Profile"
        style={{
            width: "100%",
            height: "100%",
            objectFit: "cover",
        }}
        />
        </ImageWrapper>

        <Typography variant="h6" gutterBottom>
         {values.firstName} {values.lastName}
        </Typography>

        <Typography variant="h5" gutterBottom sx={{ fontWeight: "bold", color:'#ff4444' }}>
          ${values.subscriptionID}/month
        </Typography>

        <Typography variant="body2" color="text.secondary" sx={{ mb: 3 }}>
          Enroll your child today for unlimited access to our premium childcare services.
        </Typography>

        <Typography variant="body2" color="text.secondary" sx={{ mb: 3, fontSize: "0.8rem" }}>
          Your subscription to daycare will be charged monthly to your selected payment method automatically. To cancel,
          please use the app store platform where you subscribed. Daycare cannot process cancellations directly. For
          questions about your subscription, please contact customer support.
        </Typography>

        
      </CardContent>
    </StyledCard>
    </Box>
  )
}

