import React, { useState, MouseEvent } from "react";
import { Modal, Box, List, ListItemButton, ListItemIcon, ListItemText, Typography } from "@mui/material";
import CircleIcon from "@mui/icons-material/Circle";
import Popover from "@material-ui/core/Popover";

type Status = "Needs Review" | "In Progress" | "Resolved";

interface StatusOption {
  label: Status;
  color: "error" | "warning" | "success";
  value: Status;
}

const statuses: StatusOption[] = [
  { label: "Needs Review", color: "error", value: "Needs Review" },
  { label: "In Progress", color: "warning", value: "In Progress" },
  { label: "Resolved", color: "success", value: "Resolved" },
];

interface StatusModalProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  updateFn: (status: Status) => void;
  anchorEl:any;
  anch:any
}

const StatusModal: React.FC<StatusModalProps> = ({ open, setOpen, updateFn ,anchorEl,anch}) => {
  const [position, setPosition] = useState<{ top: number; left: number }>({ top: 0, left: 0 });

  const handleClose = () => setOpen(false);

  return (
    <Popover style={{ marginLeft: 40,marginTop:-70 }}
    open={open}
    anchorReference="anchorPosition"
    anchorPosition={{ top: anch.top, left: anch.left }}
    onClose={handleClose}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'left',
    }}
  >
      <div style={{ cursor: "pointer" }}>

        <Box
          sx={{
            bgcolor: "background.paper",
            boxShadow: 3,
            borderRadius: 2,
            p: 1,
            minWidth: 150,
          }}
        >
          <List>
            {statuses.map((status) => (
              <ListItemButton
                key={status.value}
                onClick={() => {
                  updateFn(status.value);
                  handleClose();
                }}
              >
                <ListItemIcon>
                  <CircleIcon color={status.color} />
                </ListItemIcon>
                <ListItemText primary={status.label} />
              </ListItemButton>
            ))}
          </List>
        </Box>
      </div>
  </Popover>
    
  );
};

export default StatusModal;
