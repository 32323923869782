import React from 'react';
import { Box, TextField, MenuItem, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';

const states = [
  { value: 'NY', label: 'New York' },
  { value: 'CA', label: 'California' },
  // Add more states as needed
];

export default function Step2Address({ formikProps }: { formikProps: any }) {
  const { values, errors, touched, handleChange, isSubmitting, setFieldTouched, setFieldValue } = formikProps;

  return (
    <Box>
      <Typography variant="h4" component="h2" sx={{ mb: 1 ,fontFamily:'KoHo, sans-serif', color:"#000", fontWeight:"500"}}>
        Provide Your Address
      </Typography>
      <Typography variant="body1" color="text.secondary" sx={{ mb: 4 ,fontFamily:'KoHo, sans-serif', color:"#000", fontSize:"18px"}}>
        Let us know where we can reach you
      </Typography>

      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
        <TextField
          fullWidth
          name="addressLine1"
          label="Address line 1"
          value={values.addressLine1}
          onChange={handleChange}
          error={touched.addressLine1 && Boolean(errors.addressLine1)}
          helperText={touched.addressLine1 && errors.addressLine1}
          sx={{
            "& .MuiOutlinedInput-root": {
              borderRadius: "12px",
              fontFamily:"Inter, sans-serif",
              color:"#000",
              fontWeight:"regular",

            },

            '& .MuiInputLabel-root': { color: '#c4c4c4' }, 
            '& .MuiInputLabel-root.Mui-focused': { color: '#1976d2' },
          }}
        />

        <TextField
          fullWidth
          name="addressLine2"
          label="Address line 2"
          value={values.addressLine2}
          onChange={handleChange}
          error={touched.addressLine2 && Boolean(errors.addressLine2)}
          helperText={touched.addressLine2 && errors.addressLine2}
          sx={{
            "& .MuiOutlinedInput-root": {
              borderRadius: "12px",
              fontFamily:"Inter, sans-serif",
              color:"#000",
              fontWeight:"regular",
            },

            '& .MuiInputLabel-root': { color: '#c4c4c4' }, 
            '& .MuiInputLabel-root.Mui-focused': { color: '#1976d2' },
          }}
        />

        <Box sx={{ display: 'flex', gap: 2 }}>
          <TextField
            name="aptNumber"
            label="Apt#"
            value={values.aptNumber}
            onChange={handleChange}
            error={touched.aptNumber && Boolean(errors.aptNumber)}
            helperText={touched.aptNumber && errors.aptNumber}
            sx={{ width: '30%' ,
              "& .MuiOutlinedInput-root": {
              borderRadius: "12px",
              fontFamily:"Inter, sans-serif",
              color:"#000",
              fontWeight:"regular",
              
            },

            '& .MuiInputLabel-root': { color: '#c4c4c4' }, 
            '& .MuiInputLabel-root.Mui-focused': { color: '#1976d2' },
            }}
          />

          <TextField
            fullWidth
            name="city"
            label="City"
            value={values.city}
            onChange={handleChange}
            error={touched.city && Boolean(errors.city)}
            helperText={touched.city && errors.city}
            sx={{
              "& .MuiOutlinedInput-root": {
                borderRadius: "12px",
                fontFamily:"Inter, sans-serif",
              color:"#000",
              fontWeight:"regular",
              
            },

            '& .MuiInputLabel-root': { color: '#c4c4c4' }, 
            '& .MuiInputLabel-root.Mui-focused': { color: '#1976d2' },
            }}
          />
        </Box>

        <Box sx={{ display: 'flex', gap: 2 }}>
          <TextField
            
            name="state"
            label="State"
            value={values.state}
            onChange={handleChange}
            error={touched.state && Boolean(errors.state)}
            helperText={touched.state && errors.state}
            sx={{ width: '60%',
              "& .MuiOutlinedInput-root": {
              borderRadius: "12px",
              fontFamily:"Inter, sans-serif",
              color:"#000",
              fontWeight:"regular",
            },

            '& .MuiInputLabel-root': { color: '#c4c4c4' }, 
            '& .MuiInputLabel-root.Mui-focused': { color: '#1976d2' },
             }}
          >
          </TextField>

          <TextField
            name="zipcode"
            label="Zipcode"
            value={values.zipcode}
            onChange={(e) => {
              const zipCode = e.target.value.replace(/\D/g, "");
              if (zipCode.length === 10) {
                setFieldTouched("zipcode", false);
              } else {
                setFieldTouched("zipcode", true);
              }
              setFieldValue("zipcode", zipCode);
            }}
            inputProps={{ maxLength: 5, inputMode: "numeric", pattern: "[0-9]*" }}
            error={touched.zipcode && Boolean(errors.zipcode)}
            helperText={touched.zipcode && errors.zipcode}
            sx={{ width: '40%',
              "& .MuiOutlinedInput-root": {
              borderRadius: "12px",
              fontFamily:"Inter, sans-serif",
              color:"#000",
              fontWeight:"regular",
              },
  
              '& .MuiInputLabel-root': { color: '#c4c4c4' }, 
              '& .MuiInputLabel-root.Mui-focused': { color: '#1976d2' },
             }}
          />
        </Box>

        <LoadingButton
          fullWidth
          variant="contained"
          type="submit"
          loading={isSubmitting}
          sx={{
            mt: 2,
            bgcolor: '#ff4d4f',
            borderRadius: "12px",
            padding:"20px 16px",
            fontFamily:"Inter, sans-serif",
            fontWeight:"600",
            textTransform:"capitalize",
            '&:hover': {
              bgcolor: '#ff7875',
            },
          }}
        >
          Next
        </LoadingButton>
      </Box>
    </Box>
  );
}

