import React, { useState, ReactNode } from 'react';
import {
  SwipeableDrawer,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
  Box,
  Drawer as PermanentDrawer,
  AppBar,
  Toolbar,
  useMediaQuery,
  useTheme,
  Select,
  MenuItem,
  SelectChangeEvent,
  Button,
  Modal,
  styled,
} from '@mui/material';
let Logo = require('./recess-logo.png')
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import CloseIcon from '@mui/icons-material/Close';
import HomeIcon from '@mui/icons-material/Home';
import PeopleIcon from '@mui/icons-material/People';
import ChildCareIcon from '@mui/icons-material/ChildCare';
import WarningIcon from '@mui/icons-material/Warning';
import HelpIcon from '@mui/icons-material/Help';

import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import TimeSlothCard, { BookedSlotProps } from './TimeSlothCard';
import QRCodeButton from './QRCodeButton';

export enum NavigationItem {
  ACTIVE_BOOKINGS = "Active Bookings",
  CARETAKERS = "Caretakers - Adults",
  MEMBERS = "Members - Children",
  INCIDENTS = "Incidents",
  STAFF = "Staff - Coming Soon"
}

export const navigationIcons = {
  [NavigationItem.ACTIVE_BOOKINGS]: HomeIcon,
  [NavigationItem.CARETAKERS]: PeopleIcon,
  [NavigationItem.MEMBERS]: ChildCareIcon,
  [NavigationItem.INCIDENTS]: WarningIcon,
  [NavigationItem.STAFF]: HelpIcon,
};

export enum AgeGroup {
  INFANT = "Infant (Under 1)",
  YOUNG_TODDLERS = "Young Toddlers (One's)",
  OLD_TODDLERS = "Old Toddlers (Two's)",
  PRESCHOOL = "Preschool (3-5 YOs)",
  GRADE_SCHOOL = "Grade School (Over 5)"
}

export interface AgeGroupColor {
  ageGroup: AgeGroup;
  color: string;
}
interface Location {
    id: number;
    latitude: number | null;
    longitude: number | null;
    van_id: number | null;
    address: string;
    title: string;
    duration_id: number;
}

export interface SideBar {
    SideBarContent?: ReactNode;
    HeaderTitel :  ReactNode;
    NavigationFunction : any;
    children?: ReactNode;
    locations ?: Location[]; 
    updateLocationState : (id:any)=>void;
    navigateFun: (data:any) => void;
    selectedPage: "ACTIVE_BOOKINGS" | "CARETAKERS" | "MEMBERS" | "INCIDENTS" ,
    updateQRCodeState:(input:string)=>void
    tileSlothData: BookedSlotProps |null
   
}


const drawerWidth = 280;
const MainWrapper = styled(Box)(({ theme }) => ({
  "& .headercom": {
    display: "flex",
    gap: "0",
    alignItems: "center",
    
    // Mobile & Tablet View
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
      alignItems: "flex-start",
    },

    // Desktop View
    [theme.breakpoints.up("lg")]: {
      justifyContent: "space-between",
    },
  },
}));
export function SideBar({selectedPage, navigateFun, SideBarContent , HeaderTitel ,tileSlothData , NavigationFunction ,children , locations , updateLocationState ,updateQRCodeState}:SideBar) {
  const [mobileOpen, setMobileOpen] = useState(false);
  const [desktopOpen, setDesktopOpen] = useState(true);
  const [selectedItem, setSelectedItem] = useState<NavigationItem>(NavigationItem[selectedPage]);
  const [selectedLocation , setSelectedLocation]= useState<number|null|string>(localStorage.getItem('selectedLocation'))
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
   
  const LogoutButton = ({ handleLogout }:{ handleLogout:()=>void }) => {
    const [open, setOpen] = useState(false);
  
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
  
    return (
      <>
       

        <Button   sx={{
          bgcolor: '#f1544b', // Existing red color
          color: '#ffff',
          m: 2,
          p: 2,
          borderRadius: '13px',
          '&:hover': {
            bgcolor: '#c4433c', // Darker shade of red for hover
          },
          mt: "auto",
        }}
        onClick={handleOpen}
        >LogOut</Button>
  
        <Modal open={open} onClose={handleClose}>
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: 300,
              bgcolor: "background.paper",
              borderRadius: 2,
              boxShadow: 24,
              p: 3,
              textAlign: "center",
            }}
          >
            <Typography variant="h6" gutterBottom>
              Are you sure you want to logout?
            </Typography>
            <Box sx={{ display: "flex", justifyContent: "space-between", mt: 2 }}>
              <Button variant="outlined" onClick={handleClose}>
                Cancel
              </Button>
              <Button
                variant="contained"
                color="error"
                sx={{bgcolor: '#f1544b',}}
                onClick={() => {
                  handleLogout();
                  handleClose();
                }}
              >
                Confirm
              </Button>
            </Box>
          </Box>
        </Modal>
      </>
    );
  };
  


  const handleDrawerToggle = () => {
    if (isMobile) {
      setMobileOpen(!mobileOpen);
    } else {
      setDesktopOpen(!desktopOpen);
    }
  };

  const handleNavigation = (item: NavigationItem) => {
    if(item !== NavigationItem.STAFF){
      setSelectedItem(item);
      navigateFun(item)
    }
    
  };

  const handelLogout = ()=>{
    localStorage.setItem("authToken","");
    localStorage.setItem("locations","");
    localStorage.setItem("saveUserInfo","");
    navigateFun("/EmailAccountLoginBlock")
  }

  const handleDrawerClose = () => {
    if (isMobile) {
      setMobileOpen(false);
    } else {
      setDesktopOpen(false);
    }
  };

  const handleLocationChange = (e: SelectChangeEvent<unknown>) => {
    const selectedValue = e.target.value as number;
    localStorage.setItem('selectedLocation', selectedValue.toString());
      setSelectedLocation(selectedValue)
      updateLocationState(selectedValue)
  };


  const drawerContent = (
    <Box sx={{ width: drawerWidth-40, p: 2 ,overflowX:'none',overflowY: "auto", // Enable scrolling
     

      "&::-webkit-scrollbar": {
      width: "2px",
    },
    "&::-webkit-scrollbar-track": {
      background: "#ffff",
      borderRadius: "10px",
      width: "0px",
      "&:hover": {
        background: "#ffff",
       }
    },
    "&::-webkit-scrollbar-thumb": {
      background: "#ffff",
      borderRadius: "10px",
      opacity: 0, // Hidden by default
      transition: "opacity 0.3s ease-in-out",
     "&:hover": {
      background: "#f1544b",
     }
    },
    "&:hover::-webkit-scrollbar-thumb": {
      opacity: 1, 
    },
    }}>
      <Box display="flex" alignItems="center" justifyContent="space-between"  sx={{height:"90px"}}>
        <Typography variant="h4" fontWeight="bold" sx={{justifyContent:'center', alignItems:'center'}}>
        <img src={Logo} alt='logo' style={{height: '29px' , width:'150px'}}/>
        </Typography>
        <IconButton onClick={handleDrawerClose} sx={{border:'1px solid  #9499a1', borderRadius:'50%', width:'30px' ,height:'30px'}}>
          {isMobile ? <CloseIcon /> : <ChevronLeftIcon />}
        </IconButton>
      </Box>
      
      <List>
        {Object.values(NavigationItem).map((item) => {
          const Icon = navigationIcons[item];
          return (
            <ListItem
              key={item}
              button
              selected={selectedItem === item}
              sx={{
                backgroundColor: selectedItem === item ?"#f1544b": "transparent" ,
                color: selectedItem === item ? "white" : "#9499a1",
                cursor: "pointer",
                "&:hover": {
                  backgroundColor: selectedItem === item ? "darkred" : "#f1544b",
                },
               "&.Mui-selected": {
                  backgroundColor: "#f1544b", 
                  "&:hover": {
                    backgroundColor: "#f1544b",
                  },
                },
                mb:'20px',
                w:'90%',
                borderRadius : '15px'
              }}
              
              // onClick={() => setSelectedItem(item)}
              onClick={() => handleNavigation(item)}
            >
              <ListItemIcon sx={{  color: selectedItem === item ?  "#ffff":"#9499a1" }}>
                <Icon/>
              </ListItemIcon>
              <ListItemText primary={item} sx={{w:'150px'}} />
            </ListItem>
          );
        })}
        <TimeSlothCard data={tileSlothData?.data || null} />
        {SideBarContent}
      </List>
    </Box>
  );

  return (
    <>
        <MainWrapper>
    
     <AppBar
        position="fixed"
        sx={{
          width: { sm: desktopOpen ? `calc(100% - ${drawerWidth}px)` : '100%' },
          ml: { sm: desktopOpen ? `${drawerWidth}px` : 0 },
          background:'#ffff',
          color:'black'
        }}
      >
        <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        borderBottom="1px solid #932"
        paddingX={'43px'}
        height={'111px'}
        className={"headercom"}
       
      >
        {/* Date and Time */}
        
        {HeaderTitel}

        {/* Location */}
        <Box display="flex" alignItems="center" justifyContent={'center'} justifyItems={'center'}  >
          <HomeOutlinedIcon color="error"  />
       {  locations && <Select
                     value={selectedLocation} 
                    label="Location"
                    onChange={handleLocationChange}
                    sx={{ 
                      border: "none",
                      fontFamily: "Inter, sans-serif",
                      fontSize: "20px",
                      width: "100%",
                      maxWidth: "320px",
                      fontWeight: 500,                     
                      "&.MuiOutlinedInput-root": {
                      border: "none", // Remove border
                      "& fieldset": {
                        border: "none", 
                        fontFamily:'inter',
                        fontSize:'20px',
                        width:'100%',
                        fontWeight:'medimum'
                      },
                    },
                    "& .MuiSelect-icon": {
                      color: "black", // Custom color for dropdown icon
                      
                    },}}
                    IconComponent={KeyboardArrowDownOutlinedIcon}
                >
                    {locations.map((location) => (
                        <MenuItem key={location.id} value={location.id}>
                            {location.title} - {location.address} {/* Display title and address */}
                        </MenuItem>
                    ))}
                </Select>}
        
               
        </Box>
        </Box>
      </AppBar>
      </MainWrapper>
      <Box
        component="nav"
        sx={{ width: { sm: desktopOpen ? drawerWidth : 0 }, flexShrink: { sm: 0 } ,overflowX:'none' } }
      >
        <SwipeableDrawer
          variant="temporary"
          open={mobileOpen}
          onOpen={() => setMobileOpen(true)}
          onClose={() => setMobileOpen(false)}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },overflowX:'none'
          }}
        >
          {drawerContent}
          <QRCodeButton saveFn={ (input: string)=> updateQRCodeState(input) }/>

        
        <LogoutButton handleLogout={handelLogout} />
        </SwipeableDrawer>
        <PermanentDrawer
          variant="persistent"
          sx={{
            display: { xs: 'none', sm: 'block' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth , overflowX:"hidden" },overflowX:'none'
          }}
          open={desktopOpen}
        >
          {drawerContent}

          <QRCodeButton saveFn={ (input: string)=> updateQRCodeState(input) }/>

        
        <LogoutButton handleLogout={handelLogout} />
        </PermanentDrawer>
      </Box>
{(isMobile || !desktopOpen) && (
        <IconButton
          color="inherit"
          aria-label="open drawer"
          edge="start"
          onClick={handleDrawerToggle}
          sx={{ position: 'fixed', left: 16, top: 40, zIndex: theme.zIndex.drawer + 1 
                ,border:'1px solid  #9499a1',borderRadius:'50%', width:'30px' ,height:'30px'}}
          
        >
          <ChevronRightIcon  />
        </IconButton>
      )}
             
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          marginTop: '111px', // Adjust for AppBar height
          ml: { sm: desktopOpen ? `${drawerWidth}px` : 0 },
        }}
      >
        {children}

      </Box>
    </>
  );
}

