import React, { useEffect, useState } from "react"
import { Formik, Form, } from "formik"
import type { FormikProps } from "formik";
import { Box, Button, Typography, IconButton } from "@mui/material"
import ArrowBackIcon from "@mui/icons-material/ArrowBack"
import ProfileStep from "./ProfileStep"
import HealthStep from "./HealthStep"
import KidStripeScreen from "./KidStripeScreen"
import KidPreView from "./KidPreView";
import { Dialog, TextareaAutosize } from "@material-ui/core";
export interface BaseOption {
    id: string
    type: string
    attributes: {
      name: string
      activated: boolean
      active_icon: string
      inactive_icon: string
    }
  }
  
  export interface Allergen extends BaseOption {}
  export interface PottyTraining extends BaseOption {}
  export interface SolidAndPuree extends BaseOption {}
  export interface NapOption extends Omit<BaseOption, "attributes"> {
    attributes: {
      name: string
      active_icon: string
      inactive_icon: string
    }
  }
  interface StripeSubscriptionPlan {
    id: string;
    type: "stripe_subscription_plan";
    attributes: {
      title: string;
      billing_amount: number;
      billing_frequency: "month" | "year";
      active: boolean;
      start_age: number;
      end_age: number;
    };
  }
  
  export interface FormValues {
    profile_id:string;
    // Profile Step
    profileImage: File | null
    firstName: string
    lastName: string
    gender: "Male" | "Female" | "other",
    birthday:string;
    addressLine1: string
    addressLine2: string
    apt: string
    city: string
    state: string
    zipcode: string
    legalCustody: string
    language: string
  
    // Health Step
    allergens: string[]
    potty_training_id: string
    vaccination_files: File[]
    special_notes: string
    solid_and_puree_id: string
    nap_id: string
    nap_start_time: string
    nap_end_time: string

    // subscription
    subscriptionID : string
    enrolled:boolean,
    subscriptionPlan : StripeSubscriptionPlan[] | null;
    isEdit: boolean

    //step
    step:number|null
  }
  
  
interface MultiStepFormProps {
  allergens: Allergen[]
  pottyTrainingOptions: PottyTraining[]
  solidAndPureeOptions: SolidAndPuree[]
  napOptions: NapOption[]
  submitFn :(step:number,values:any)=>void;
  kidProfileId:string,
  kidinival : FormValues
  ReasonText : string,
  DeleteText:string,
  deletedProfileMessage:string,
  setReasonText: (ReasonText: any) => void;
  setDeleteText: (DeleteText: any) => void;
  handleUnenroll: () => void;
  handleDeleteProfile:()=>void;
  unenrollMessage: string;
  childernId: number | null
  handelDrawerfn:()=>void;
}

const initialValues: FormValues = {
  profile_id:"",
  profileImage: null,
  firstName: "",
  lastName: "",
  gender: "other",
  birthday:"",
  addressLine1: "",
  addressLine2: "",
  apt: "",
  city: "",
  state: "",
  zipcode: "",
  legalCustody: "",
  language: "",
  allergens: [],
  potty_training_id: "",
  vaccination_files: [],
  special_notes: "",
  solid_and_puree_id: "",
  nap_id: "",
  nap_start_time: "",
  nap_end_time: "",
  subscriptionID : "",
  enrolled: false,
  subscriptionPlan:null,
  isEdit:false,
  step:null
}

const validateProfileStep = (values: FormValues) => {
  const errors: Partial<FormValues> = {}
  if (!values.firstName) errors.firstName = "First name is required"
  if (!values.lastName) errors.lastName = "Last name is required"
  if (!values.profileImage) errors.lastName = "Profile image is required"
  if (!values.addressLine1) errors.addressLine1 = "Address line 1 is required"
  if (!values.city) errors.city = "City is required"
  if (!values.state) errors.state = "State is required"
  if (!values.zipcode) errors.zipcode = "Zipcode is required"
  if (values.zipcode && !/^\d{5}$/.test(values.zipcode)) errors.zipcode = "Zipcode must be 5 digits"
  if (!values.legalCustody) errors.legalCustody = "Legal custody is required"
  if (!values.language) errors.language = "Language is required"
  if(!values.birthday) errors.birthday="birthday is required"
  return errors
}

const validateHealthStep = (values: FormValues) => {
  const errors: Partial<FormValues> = {}
  if (values.allergens.length === 0) {
    errors.allergens = ["Please select at least one allergen"]
  }
  if (!values.potty_training_id) {
    errors.potty_training_id = "Please select a potty training status"
  }
  if (!values.solid_and_puree_id) {
    errors.solid_and_puree_id = "Please select a food preference"
  }
  if (!values.nap_id) {
    errors.nap_id = "Please select a nap preference"
  }
  if (values.nap_id && values.nap_id !== "1") {
    if (!values.nap_start_time) {
      errors.nap_start_time = "Please enter nap start time"
    }
    if (!values.nap_end_time) {
      errors.nap_end_time = "Please enter nap end time"
    }
  }
  return errors
}
const validateStripe = ()=>{
    return {}
}
export  function AddKId({
  allergens,
  pottyTrainingOptions,
  solidAndPureeOptions,
  napOptions,
  submitFn,
  kidProfileId,
  kidinival,
  ReasonText,
  DeleteText,
  deletedProfileMessage,
  setReasonText,
  setDeleteText,
  handleUnenroll,
  handleDeleteProfile,
  unenrollMessage,
  childernId,
  handelDrawerfn
}: MultiStepFormProps) {
  const [step, setStep] = useState(0);
  const [isReEnrollled,setIsReEnrolled]=useState(false)
  const [unenrollChildDialog, setUnenrollChildDialog] = useState(false)
  const [unenrolledAcknolageDialog, setunenrolledAcknolageDialog] = useState(false)
  const [deleteChildPorfleDialog, setdeleteChildPorfleDialog] = useState(false)
  const [deleteChildPorfleAcknolageDialog, setDeleteChildPorfleAcknolageDialog] = useState(false)
  const isEdit = !(step === 2 && initialValues.subscriptionID)
  const steps = ["Child Profile", "Health Information","Subscription Confirmation"]

  const handleSubmit = (values: FormValues, { setSubmitting }: { setSubmitting: (isSubmitting: boolean) => void } ) => {
    if (step < steps.length - 1) {
      submitFn(step , values)
      setStep(step + 1);
      setSubmitting(false)
      
    } else {
      submitFn(step, values)
      setSubmitting(false)
     
    }
  }

const handleReEnroll=()=>{
  setIsReEnrolled(true);
  setStep(2)
}
  const handleBack = () => {
    
    setStep(Math.max(0, step - 1))
  }
 
  const stepValidations = [validateProfileStep, validateHealthStep,validateStripe]

  const showUnenrollDialog = () =>{
    setUnenrollChildDialog(true)
  }
  const hideUnenrollDialog = () =>{
    setUnenrollChildDialog(false)
  }

  const showAcknolageDialog = () =>{
    handleUnenroll()
    setunenrolledAcknolageDialog(true)
    setUnenrollChildDialog(false)
  }
  const hideAcknolageDialog = () =>{
    setunenrolledAcknolageDialog(false)
  }

 

  const showDeleteChildPorfleDialog = () =>{
    setdeleteChildPorfleDialog(true)
  }
  const hideDeleteChildPorfleDialog = () =>{
    setdeleteChildPorfleDialog(false)
  }
  
  const showDeleteChildPorfleAcknolageDialog = () =>{
    handleDeleteProfile()
    setDeleteChildPorfleAcknolageDialog(true)
    setdeleteChildPorfleDialog(false)
  }
  const hideDeleteChildPorfleAcknolageDialog = () =>{
    handelDrawerfn();
    setDeleteChildPorfleAcknolageDialog(false)
  }


  //deleteChildporfleDialog

  return (
    <Formik
      initialValues={{...initialValues,...kidinival, }}
      onSubmit={handleSubmit}
      validate={(values) => stepValidations[step](values)}
      validateOnMount={true}
      // enableReinitialize={true} 
    >
      
      {(formikProps: FormikProps<FormValues>) => {

        useEffect(() => {
          if (step === 2) {
           
            formikProps.setFieldValue("subscriptionID", kidinival.subscriptionPlan && kidinival.subscriptionPlan[0].attributes.billing_amount);
            formikProps.setFieldValue("subscriptionPlan", kidinival.subscriptionPlan);
            formikProps.setFieldValue("step", kidinival.step);
            formikProps.setFieldValue("enrolled", kidinival.enrolled);

          }
         
        }, [step, kidinival]);
       return (<Form>
          <Box sx={{ maxWidth: 1000, mx: "auto", p: 3 }}>
            <Box sx={{ mb: 4, display: "flex", alignItems: "center" }}>
              {step > 1 && (
                <IconButton onClick={handleBack} sx={{ mr: 1 }}>
                  <ArrowBackIcon />
                </IconButton>
              )}
              <Typography variant="h6">{steps[step]}</Typography>
             
            </Box>

            {step === 0 && <ProfileStep formikProps={formikProps}   handleReEnroll={handleReEnroll} isEnrolled={kidinival.enrolled}/>}
            {step === 1 && (
              <HealthStep
                formikProps={formikProps}
                allergens={allergens}
                pottyTrainingOptions={pottyTrainingOptions}
                solidAndPureeOptions={solidAndPureeOptions}
                napOptions={napOptions}
              />
            )}

            {!formikProps.values.isEdit && step===2 && !isReEnrollled ? (
              <KidStripeScreen
                formikProps={formikProps}
              
              />
            ): !isReEnrollled && step  === 2 &&  (
                <KidPreView formikProps={formikProps} />
            )}
            {isReEnrollled&&step===2 &&<KidStripeScreen
                formikProps={formikProps}
              
              />  }
            {step === 3 &&  (
                <KidPreView formikProps={formikProps} />
            )}

<div style={{display:"flex", justifyContent:"center", alignItems:"center", flexDirection:"column", margin: "16px 0"}}>
  {childernId &&
  <>
  {!isReEnrollled &&<Button onClick={showDeleteChildPorfleDialog} style={{color: "#F1544B", borderRadius: "12px", fontWeight:"600", fontSize:"16px", width:"180px", textTransform:"capitalize"}}> Delete Profile</Button>}
  
  {kidinival.isEdit&& kidinival.enrolled &&
  <Button onClick={showUnenrollDialog} style={{ color: "#F89F34", borderRadius: "12px", fontWeight:"600", fontSize:"16px", width:"180px", textTransform:"capitalize" , textDecoration:"underline"}}> Unenroll Child </Button>}
  </>
  }

</div>

<Dialog
  open={unenrollChildDialog}
  //onClose={handleCloseDialog}
  aria-labelledby="alert-dialog-title"
  aria-describedby="alert-dialog-description"
  fullWidth={true}
  PaperProps={{
    style: {
        width: '516px',
        maxWidth: '100%',
        overflow: 'hidden',
        padding: '35px',
        borderRadius: "30px"
    },
  }}
>
  <div>
    <Typography variant="h6"> Unenrolling </Typography>
    <Typography variant="subtitle1"> <span style={{color:"#F1544B"}}>*</span>Unenrolling your child will remove them from the recurring 
      monthly fee and you will no longer be able to book FlexCare for them. 
      You can re-enroll later if needed.</Typography>

    <Typography variant="h6" style={{marginTop:"16px"}}> Reason for unenrolling? </Typography>

    <TextareaAutosize
     value={ReasonText}
     onChange={(e) => setReasonText(e.target.value)}
     minRows={5} style={{width: "100%", border: "1px solid #C4C4C4",borderRadius: "15px", padding:"16px"}} />
   
   <div style={{display:"flex", justifyContent:"center", flexDirection: "column" , gap: "16px", marginTop: "24px"}}> 
   <Button  style={{width:"100%", height:"50px", background: "#F1544B", borderRadius: "12px", fontWeight:"600", color:"#fff", fontSize:"16px" }} onClick={showAcknolageDialog}> Confirm</Button>
   <Button onClick={hideUnenrollDialog} style={{width:"100%", height:"50px", border:"1px solid #9499A1", borderRadius: "12px",fontSize:"16px", color:"#9499A1", fontWeight:"600"}}> Cancel </Button>
   </div>
  </div>
</Dialog>

<Dialog
  open={unenrolledAcknolageDialog}
  //onClose={handleCloseDialog}
  aria-labelledby="alert-dialog-title"
  aria-describedby="alert-dialog-description"
  fullWidth={true}
  PaperProps={{
    style: {
        width: '440px',
        maxWidth: '100%',
        overflow: 'hidden',
        padding: '35px',
        borderRadius: "12px"
    },
  }}
>
   <Typography variant="h5" style={{textAlign:"center"}}>
    {unenrollMessage}
   </Typography>
   
   <Button onClick={hideAcknolageDialog} style={{width:"156px", height:"50px",  fontWeight:"500", color:"#535656", fontSize:"16px", textTransform: "capitalize", margin:"0 auto"}}>
      Close
   </Button>


</Dialog>

<Dialog
  open={deleteChildPorfleDialog}
  //onClose={handleCloseDialog}
  aria-labelledby="alert-dialog-title"
  aria-describedby="alert-dialog-description"
  fullWidth={true}
  PaperProps={{
    style: {
        width: '516px',
        maxWidth: '100%',
        overflow: 'hidden',
        padding: '35px',
        borderRadius: "30px"
    },
  }}
>
  <div>
    <Typography variant="h6"> Delete Profile </Typography>
    <Typography variant="subtitle1"> <span style={{color:"#F1544B"}}>*</span>Deleting your child profile will remove them from our 
    records and database. If you want to re-enroll them in the future, you will have to 
    recreate their profile first.</Typography>

    <Typography variant="h6" style={{marginTop:"16px"}}> Reason for deleting? </Typography>

    <TextareaAutosize
     value={DeleteText}
     onChange={(e) => setDeleteText(e.target.value)}
     minRows={5} style={{width: "100%", border: "1px solid #C4C4C4",borderRadius: "15px", padding:"16px"}} />
   
   <div style={{display:"flex", justifyContent:"center", flexDirection: "column" , gap: "16px", marginTop: "24px"}}> 
   <Button  style={{width:"100%", height:"50px", background: "#F1544B", borderRadius: "12px", fontWeight:"600", color:"#fff", fontSize:"16px" }} onClick={showDeleteChildPorfleAcknolageDialog}> Confirm</Button>
   <Button onClick={hideDeleteChildPorfleDialog} style={{width:"100%", height:"50px", border:"1px solid #9499A1", borderRadius: "12px",fontSize:"16px", color:"#9499A1", fontWeight:"600"}}> Cancel </Button>
   </div>
  </div>
</Dialog>

<Dialog
  open={deleteChildPorfleAcknolageDialog}
  //onClose={handleCloseDialog}
  aria-labelledby="alert-dialog-title"
  aria-describedby="alert-dialog-description"
  fullWidth={true}
  PaperProps={{
    style: {
        width: '440px',
        maxWidth: '100%',
        overflow: 'hidden',
        padding: '35px',
        borderRadius: "12px"
    },
  }}
>
   <Typography variant="h5" style={{textAlign:"center"}}>
   {deletedProfileMessage}
   </Typography>
   
   <Button onClick={hideDeleteChildPorfleAcknolageDialog} style={{width:"156px", height:"50px",  fontWeight:"500", color:"#535656", fontSize:"16px", textTransform: "capitalize", margin:"0 auto"}}>
      Close
   </Button>


</Dialog>
           
    <Button
      type="submit"
      fullWidth
      variant="contained"
      disabled={ formikProps.isSubmitting}
      sx={{
        mt: 2,
        borderRadius: 3,
        bgcolor: "#f15642",
        "&:hover": {
          bgcolor: "#d64937",
        },
      }}
    >            
      {step === steps.length - 1 ? "Submit" : "Next"}
    </Button>
  </Box>
    </Form>)
}}
</Formik>
)
}

