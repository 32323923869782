
import React from "react";
// Customizable Area Start
import { Box, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Avatar, IconButton,} from "@mui/material";
import { ArrowForwardIos } from "@mui/icons-material";
import { SideBar } from "../../../components/src/SideBar";
import IncidentFormDrawer from "../../../components/src/IncidentFormDrawer";
import CommonHerderDateinput from "../../../components/src/CommonHerderDateinput";
import StatusModal from "../../../components/src/StatusModal";
import StringModal from "../../../components/src/StringModal";
// Customizable Area End
import DashboardController, {
  Props,
  webConfigJSON
} from "./DashboardController.web";
import { Dayjs } from "dayjs";

export default class DashbordIncident extends DashboardController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  async componentDidMount(): Promise<void> {
    this.getLocatations()
    this.sendGetAPIRequest(webConfigJSON.getIncidentList, 'apiIncidentCallId')
    this.sendGetAPIRequest(webConfigJSON.getTimeSloth,"apiCallTimeSlothgetCallId");
  }
  componentDidUpdate(prevProps: Props, prevState: any) {
    if (prevState.selectedLocation !== this.state.selectedLocation && prevState.selectedDate !== this.state.selectedDate) {
      this.sendGetAPIRequest(webConfigJSON.getIncidentList, "apiIncidentCallId");
      this.sendGetAPIRequest(webConfigJSON.getTimeSloth,"apiCallTimeSlothgetCallId");
    }
  }
  IncidentRow = ({ data, index , rawdata}: {
    data: { avatar: string, name: string,id:string, date: string, type: string, location: string, age: string | number, injury: string,status :string}, index: number
    , rawdata:any
  }) => {
    

  return(
    <TableRow
      key={index}
      style={{
        backgroundColor: index % 2 === 0 ? "#fde2e4" : "#ffffff",
      }}
    >
      <TableCell onClick={(e) => this.handleCellClick(e)} style={{ cursor: "pointer" }}>
        <div style={{ display: "flex", alignItems: "center" }}>
        <div
      style={{
        width: "18px",
        height: "18px",
        cursor: "pointer",
        background: this.getStatusColor(data.status),
        borderRadius: "50%",
        marginRight: "10px",
      
            }}
            onClick={()=>this.selectStatuesIncidentOpenFn(data.id)} 
    ></div>
          
          <Avatar
            src={data.avatar}
            alt={data.name}
            style={{ marginRight: "10px" }}
          />
          {data.name}
        </div>
      </TableCell>
      <TableCell>{data.age} Yrs</TableCell>
      <TableCell>{data.date}</TableCell>
      <TableCell>{data.type}</TableCell>
      <TableCell>{data.injury}</TableCell>
      <TableCell>{data.location}</TableCell>
      <TableCell>
        <IconButton onClick={()=>this.openIncidentDrawer(rawdata)}>
          <ArrowForwardIos fontSize="small" />
        </IconButton>
      </TableCell>
    </TableRow>
  )};

  // Customizable Area End

  render() {
    // Customizable Area Start

    const incidentData = this.state.IncidentData?.data;
   

    let childData = this.state.SelectedDrawerItemData?.attributes && 'child' in this.state.SelectedDrawerItemData.attributes
      ? {
          id: "",
          name: this.state.SelectedDrawerItemData.attributes.child.name ,
          image: this.state.SelectedDrawerItemData.attributes.child.image,
          age: this.state.SelectedDrawerItemData.attributes.child.age ,
        }
      : { id: "", name: "", image: "", age: "" };
       

    return (
      //Merge Engine DefaultContainer
      <Box width={"100vw"} height={"100vh"}>
        <SideBar selectedPage="INCIDENTS"
         updateQRCodeState={(data)=>this.updateQRCodeState(data)}
         tileSlothData={this.state.TimeSlothData}
          data-test-id = "sidebar"
          navigateFun={this.navigateFun}
          SideBarContent={<></>}
          locations={this.state.locations || []}
          HeaderTitel={<Typography
            variant="h6"
            fontWeight="semibold"
            fontFamily={"Inter"}
            fontSize={"28px"}
          >
            {/* {this.formatDate(Date.now())} */}
            <CommonHerderDateinput data-test-id="header_date" selectedDate={this.state.selectedDate} setSelectedDate={(e)=>this.setState({selectedDate:e})}  />
          </Typography>
          }
          NavigationFunction={this.props.navigation.navigation}
          updateLocationState={this.updateLocationState}
        >
          <div style={{ padding: "20px" }}>
            <Typography
              variant="h6"
              gutterBottom
              style={{ fontWeight: "bold", marginBottom: "16px" }}
            >
              Incident Report
            </Typography>
            <TableContainer component={Paper}>
              <Table>
                <TableHead sx={{ marginBottom: "30px" }}>
                  <TableRow style={{ backgroundColor: "#f8d7da", width: "100%" }}>
                    {[
                      "Supervisor Status",
                      "Age",
                      "Date of Incident",
                      "Type of Incident",
                      "Injury",
                      "Location",
                      "",
                    ].map((header, index) => (
                      <TableCell
                        key={index}
                        style={{ fontWeight: "bold", fontSize: 16 }}
                      >
                        {header}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                 
                  {incidentData && incidentData.map((data, index) => (
                    this.IncidentRow({ 
                      data: {
                        avatar: 'child' in data.attributes ? data.attributes.child.image : '',
                        name:  'child' in data.attributes ? data.attributes.child.name : '',
                        date: data.attributes.date,
                        type:  data.attributes.subtype,
                        location:  data.attributes.location_in_facility,
                        age: 'child' in data.attributes ? data.attributes.child.age : '',
                        injury: data.attributes.incident_type,
                        status: data.attributes.status,
                        id:data.id
                      }, 
                      index ,rawdata:data
                    })
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <IncidentFormDrawer open={this.state.OpenDrawerDashboard} setOpen={() => this.toggleDrawerDashboardState()}
              editFlag={true}
              incidentData={this.state.SelectedDrawerItemData}
              childData={childData} 
              onSubmit={(data) => this.PatchUpdateIncidentApiCall(data,this.state.SelectedIncidentId)}
               data-test-id="inc_form" />
            <StatusModal
              anchorEl={this.state.SelectedIncidentId} 
              anch={this.state.anchorPosition}
              data-test-id="status_modal"
              open={this.state.openSelectedStatus}
          setOpen={()=>this.selectStatuesIncidentOpenFn()} 
          updateFn={(status)=>this.PatchIncidentApiCall({status:status ,id :this.state.SelectedIncidentId})}          
            />
          <StringModal  message={this.state.QrMsg}/>
          </div>
        </SideBar>
      </Box>
      //Merge Engine End DefaultContainer
    );
    // Customizable Area End
  }
}

// Customizable Area Start

// Customizable Area End
