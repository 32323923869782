import  React from "react"
import type { FormikProps } from "formik"
import { Box, Typography, Grid, TextField, Button } from "@mui/material"
import { format } from "date-fns"
import { Allergen, PottyTraining, SolidAndPuree, NapOption, FormValues } from "./AddKId"
import FilePreview from "./FilePreview"

interface HealthStepProps {
  formikProps: FormikProps<FormValues>
  allergens: Allergen[]
  pottyTrainingOptions: PottyTraining[]
  solidAndPureeOptions: SolidAndPuree[]
  napOptions: NapOption[]
}

export default function HealthStep({
  formikProps,
  allergens,
  pottyTrainingOptions,
  solidAndPureeOptions,
  napOptions,
}: HealthStepProps) {
  const { values, setFieldValue, errors, touched } = formikProps

  const handleAllergenSelect = (allergerId: string) => {
    let newSelection: string[]
    if (allergerId === "2") {
      newSelection = ["2"]
    } else if (values.allergens.includes("2")) {
      newSelection = [allergerId]
    } else {
      if (values.allergens.includes(allergerId)) {
        newSelection = values.allergens.filter((id) => id !== allergerId)
      } else {
        newSelection = [...values.allergens, allergerId]
      }
    }
    setFieldValue("allergens", newSelection)
  }

  const handleSingleSelect = (field: string, value: string) => {
    setFieldValue(field, value)
  }

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = Array.from(event.target.files || [])
    setFieldValue("vaccination_files", [...values.vaccination_files, ...files])
  }

  const handleTimeChange = (field: string, event: React.ChangeEvent<HTMLInputElement>) => {
    const time24 = event.target.value
    if (time24) {
      const date = new Date(`2000-01-01T${time24}`)
      const time12 = format(date, "hh:mm a")
      setFieldValue(field, time12)
    } else {
      setFieldValue(field, "")
    }
  }
  const removeVaccinationFile = (index: number) => {
    setFieldValue("vaccination_files", 
      values.vaccination_files.filter((_, i) => i !== index)
    );
  };

  const renderOptionGrid = (
    options: (Allergen | PottyTraining | SolidAndPuree | NapOption)[],
    selectedValue: string | string[],
    field: string,
    isMultiple = false,
  ) => (
    <Grid container spacing={3} sx={{ mb: 4 }}>
      {options.map((option) => {

      
        const isSelected = isMultiple ? (Array.isArray(selectedValue) ? selectedValue : [selectedValue])
        .map(String)
        .includes(String(option.id)) : selectedValue === option.id
      
        return (
          <Grid item xs={4} key={option.id}>
            <Box
              onClick={() => (isMultiple ? handleAllergenSelect(option.id) : handleSingleSelect(field, option.id))}
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                cursor: "pointer",
                p: 1,
                borderRadius: 1,
                "&:hover": {
                  bgcolor: "rgba(0, 0, 0, 0.04)",
                },
              }}
            >
              <Box
                sx={{
                  width: 48,
                  height: 48,
                  position: "relative",
                  mb: 1,
                }}
              >
                <img
                  src={isSelected ? option.attributes.active_icon : option.attributes.inactive_icon}
                  alt={option.attributes.name}
                  
                  style={{ objectFit: "contain", width:'50px' }}
                />
              </Box>
              <Typography
                variant="body2"
                align="center"
                sx={{
                  color: isSelected ? "primary.main" : "text.primary",
                  fontWeight: isSelected ? 500 : 400,
                }}
              >
                {option.attributes.name}
              </Typography>
            </Box>
          </Grid>
        )
      })}
    </Grid>
  )

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 4 }}>
      {/* Allergens Section */}
      <Box>
        <Typography variant="h6" sx={{ mb: 2 }}>
          Allergens 
        </Typography>
        {renderOptionGrid(allergens, values.allergens, "allergens", true)}
      </Box>

      {/* Potty Training Section */}
      <Box>
        <Typography variant="h6" sx={{ mb: 2 }}>
          Potty Training
        </Typography>
        {renderOptionGrid(pottyTrainingOptions, values.potty_training_id, "potty_training_id")}
      </Box>

       {/* Solid and Puree Section */}
       <Box>
        <Typography variant="h6" sx={{ mb: 2 }}>
          Food Preference
        </Typography>
        {renderOptionGrid(solidAndPureeOptions, values.solid_and_puree_id, "solid_and_puree_id")}
      </Box>

      {/* Naps Section */}
      <Box>
        <Typography variant="h6" sx={{ mb: 2 }}>
          Naps
        </Typography>
        {renderOptionGrid(napOptions, values.nap_id, "nap_id")}

        {values.nap_id && values.nap_id !== "1" && (
          <Grid container spacing={2} sx={{ mt: 2 }}>
            
            <Grid item xs={6}>
              <TextField
                fullWidth
                label="Typical Start Time"
                value={values.nap_start_time}
                type="time"
                onChange={(e) => setFieldValue("nap_start_time", e.target.value)}
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  sx: { borderRadius: 3 } 
                }}
             
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                label="Nap duration (h)."
                value={values.nap_end_time}
                type="number"
                onChange={(e:React.ChangeEvent<HTMLInputElement>) => setFieldValue("nap_end_time",  e.target.value)}
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  sx: { borderRadius: 3 } 
                }}

              />
            </Grid>
          </Grid>
        )}
      </Box>

      {/* Vaccination Files Section */}
      <Box>
        <Typography variant="h6" sx={{ mb: 2 }}>
          Vaccination Card
        </Typography>
        <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
          Please upload the most recent vaccination card of your child.
        </Typography>
        <Box sx={{ mb: 2 }}>
          {values.vaccination_files.map((file, index) => (
            <FilePreview  file={file} key={index}  removeFn ={()=>{removeVaccinationFile(index)}}/>
          ))}
        </Box>
        <Button variant="outlined" component="label" fullWidth sx={{ mb: 1 ,borderRadius:3 }}>
          Upload Image
          <input hidden accept="image/*" multiple type="file" onChange={handleFileChange} />
        </Button>
        <Button variant="text" sx={{color:'red'}} fullWidth onClick={() => setFieldValue("vaccination_files", [])}>
          Remove All
        </Button>
      </Box>

      {/* Special Notes Section */}
      <Box>
        <Typography variant="h6" sx={{ mb: 2 }}>
          Special Notes
        </Typography>
        <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
          Please use this space to tell us anything that may help us provide the best care for your child.
        </Typography>
        <TextField
          fullWidth
          multiline
          rows={4}
          placeholder="Additional Information"
          value={values.special_notes}
          onChange={(e) => setFieldValue("special_notes", e.target.value)}
          InputProps={{
            sx: { borderRadius: 3 } 
          }}
        />
      </Box>

     
    </Box>
  )
}

