import React, { useEffect, useState } from "react";
import { Modal, Box, Typography, Snackbar } from "@mui/material";

interface StringModalProps {
  message?: string;
}

const StringModal: React.FC<StringModalProps> = ({ message }) => {
  const [open, setOpen] = useState<boolean>(!!message);
  const [toastOpen, setToastOpen] = useState<boolean>(!!message);

  useEffect(() => {
    setOpen(!!message);
    setToastOpen(!!message);
  }, [message]);

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Modal open={open} onClose={handleClose}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            borderRadius: 2,
            textAlign: "center",
            minWidth: 300,
          }}
        >
          <Typography variant="h6">{message}</Typography>
        </Box>
      </Modal>
      <Snackbar
        open={toastOpen}
        autoHideDuration={3000}
        message={message}
        onClose={() => setToastOpen(false)}
      />
    </>
  );
};

export default StringModal;
