export const parseTime = (time: string) => {
    const match = time.match(/(\d+)\s?(am|pm)/i);
    if (!match) return 0;
    
    const [_, hour, period] = match;
    let parsedHour = parseInt(hour, 10);
    if (period.toLowerCase() === "pm" && parsedHour !== 12) {
      parsedHour += 12;
    } else if (period.toLowerCase() === "am" && parsedHour === 12) {
      parsedHour = 0;
    }

    return parsedHour;
  };