import { Button } from '@mui/material';
import React, { useRef, useState } from 'react';
import QrCodeIcon from '@mui/icons-material/QrCode';

interface QRCodeButtonProps {
  saveFn: (input: string) => void; // Function to handle the typed string
}

function  QRCodeButton ({ saveFn }:QRCodeButtonProps)  {
  const [isInputVisible, setIsInputVisible] = useState(false); // State to control input visibility
  const inputRef = useRef<HTMLInputElement>(null); // Ref to focus on the input field
  const [Scan , setScan] = useState("")
  // Handle button click to show and focus on the input field
  const handleButtonClick = () => {
    setIsInputVisible((prev) => !prev); // Toggle input visibility
    setScan("Ready to scan! Please scan with a QR code reader.")
    setTimeout(() => inputRef.current?.focus(), 0); // Focus on the input after it becomes visible
  };

  // Handle Enter key press in the input field
  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      setScan("")
      const inputValue = event.currentTarget.value.trim(); // Get the typed string
      console.log('Typed string:', inputValue); // Log the string to the console
      saveFn(inputValue); // Call the saveFn prop with the typed string
      setIsInputVisible(false); // Hide the input field
      event.currentTarget.value = ''; // Clear the input field
    }
  };

  return (
    <div>
      {/* Button to trigger the input field */}
      <Button onClick={handleButtonClick} sx={{
          bgcolor: '#f1544b', // Existing red color
          color: '#ffff',
          m: 2,
          p: 2,
          width:'90%',
          borderRadius: '13px',
          '&:hover': {
            bgcolor: '#c4433c', // Darker shade of red for hover
          },
        }} >
      <QrCodeIcon/> &nbsp; Scan Child In /Out
      </Button>
      

      {/* Hidden input field */}
      {isInputVisible && (
        <>
       <div style={{ 
  position: 'fixed', 
  top: '50%', 
  left: '50%', 
  transform: 'translate(-50%, -50%)', 
  background: 'rgba(0, 0, 0, 0.8)', 
  color: 'white', 
  padding: '20px', 
  borderRadius: '8px',
  textAlign: 'center',
  fontSize: '18px'
}}>
  Scanning...
  <input
    ref={inputRef}
    type="text"
    onKeyDown={handleKeyDown}
    style={{ 
      position: 'absolute', 
      top: 0, 
      left: 0, 
      width: '1px', 
      height: '1px', 
      opacity: 0 
    }}
    placeholder="Type and press Enter"
  />
</div>

        
        </>
      )}
    </div>
  );
};
export default QRCodeButton;