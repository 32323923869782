import React from "react"
import {
    Drawer,
    Box,
    Typography,
    Avatar,
    Button,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Divider,
    Grid,
    IconButton,
  } from "@mui/material"
  import CloseIcon from "@mui/icons-material/Close"
  import type {  Kid } from "../../blocks/dashboard/src/DashboardController.web"
  import AddIcon from '@mui/icons-material/Add';
  
  interface ChildProfileDrawerProps {
    open: boolean
    setOpen: () => void
    data: Kid|null
    onIncidentReport: () => void
  }
  
  export default function ChildProfileDrawer({ open, setOpen, data, onIncidentReport }: ChildProfileDrawerProps) {
    return (
      <Drawer
        anchor="right"
        open={open}
        onClose={() => setOpen()}
        PaperProps={{
          sx: { width: { xs: "100%", sm: 500 ,lg:620 }, p: 2 ,borderRadius:4, },
        }}
      >
      { data?.attributes && <Box sx={{ position: "relative" }}>
          <IconButton onClick={() => setOpen()} sx={{ position: "absolute", right: 0, top: 0 }}>
            <CloseIcon />
          </IconButton>
  
          {/* Header */}
          <Box sx={{ display: "flex", alignItems: "center",pb:3, mb: 5, mt: 4 }}>
            <Avatar src={data.attributes?.child_profile_image} sx={{ width: 60, height: 60, mr: 2 }} />
            <Box sx={{ flex: 1 }}>
              <Typography variant="h6">
                {data.attributes.first_name} {data.attributes.last_name}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                {data.attributes.age} years old
              </Typography>
            </Box>
            <Button variant="contained" color="error" onClick={onIncidentReport}>
             <AddIcon/> Incident Report
            </Button>
          </Box>
  
          {/* Caretakers */}
          <Typography variant="h6" sx={{ mb: 1 ,fontWeight:'bold' }}>
            Caretakers
          </Typography>
          <List>
            {data.attributes.care_taker.map((caretaker) => (
              <ListItem key={caretaker.id}>
                <ListItemAvatar>
                  <Avatar>{caretaker.full_name[0]}</Avatar>
                </ListItemAvatar>
                <ListItemText primary={caretaker.full_name} />
              </ListItem>
            ))}
          </List>
  
          <Divider sx={{ my: 2 }} />
  
          {/* Personal Information */}
          <Typography variant="h6" sx={{ mb: 2 , fontWeight:'bold' }}>
            Personal Information
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Typography variant="body2" color="text.secondary">
                Date of Birth
              </Typography>
              <Typography>{data.attributes.date_of_birth}</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body2" color="text.secondary">
                Gender
              </Typography>
              <Typography>{data.attributes.gender}</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body2" color="text.secondary">
                Address
              </Typography>
              <Typography>
                {data.attributes.address1}
                {data.attributes.address2 && `, ${data.attributes.address2}`}
              </Typography>
              <Typography>
                {data.attributes.city}, {data.attributes.state} {data.attributes.zip_code}
              </Typography>
            </Grid>
          </Grid>
  
          <Divider sx={{ my: 2 }} />
  
          {/* Allergens */}
          <Typography variant="h6" sx={{ mb: 2, fontWeight:'bold'  }}>
            Allergens
          </Typography>
          <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1 }}>
            {data.attributes.allergens.map((allergen) => (
              <Box
                key={allergen.allergens_id}
                sx={{
                  p: 1,
                  border: 1,
                  borderColor: "divider",
                  borderRadius: 1,
                }}
              >
                <Typography variant="body2">{allergen.allergens_name}</Typography>
              </Box>
            ))}
          </Box>
  
          {/* Special Notes */}
          {data.attributes.special_note && (
            <>
              <Divider sx={{ my: 2 }} />
              <Typography variant="h6" sx={{ mb: 1 }}>
                Special Notes
              </Typography>
              <Typography>{data.attributes.special_note.special_note_description}</Typography>
            </>
          )}
        </Box>}
      </Drawer>
    )
  }
  
  